import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import FormLoginUser from "../../components/FormLogin/FormLoginUser";
const Login = () => {
  return (
    <Root>
      <Row gutter={16} justify="center">
        <Col md={14} lg={16}>
          <WrapLeftContent>
            <TextWelcome>Welcome to</TextWelcome>
            <TextITSummit>CTI IT Infrastructure Summit 2022</TextITSummit>
            <ImageTitle
              src="https://event.computradetech.com/images/ITSummit2022/summittitle.png"
              alt=""
            />
            <ImageDate
              src="https://event.computradetech.com/images/ITSummit2022/summitdate.png"
              alt=""
            />
            <TextNotes>
              Please keep in mind that before using the CTI Event Platform, you
              must first activate your account by opening an email with the
              subject [Approval Notification].
            </TextNotes>
          </WrapLeftContent>
        </Col>
        <Col md={10} lg={8}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
            }}
          >
            <Form>
              <LogoCTI
                src="https://event.computradetech.com/images/iko21/CTIGroup.png"
                alt=""
              />
              <FormLoginUser />
            </Form>
          </div>
          <TextNotesMobile>
            Please keep in mind that before using the CTI Event Platform, you
            must first activate your account by opening an email with the
            subject [Approval Notification].
          </TextNotesMobile>
        </Col>
      </Row>
    </Root>
  );
};

export default Login;
const Root = styled.div`
  background-image: url("https://event.computradetech.com/images/ITSummit2022/summitloginbg.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px 50px 0 60px;
  overflow: auto;
  @media (max-width: 576px) {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Form = styled.div`
  padding: 20px;
  width: 350px;
  height: 550px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.3);
  @media (max-width: 1200px) {
    width: 300px;
    height: 500px;
  }
  @media (max-width: 992px) {
    width: 350px;
    height: 550px;
  }
  @media (max-width: 576px) {
    width: 300px;
    height: 450px;
  }
`;
const TextWelcome = styled.p`
  margin: 0;
  font-weight: 400;
  color: white;
  font-size: 30px;
  @media (max-width: 992px) {
    font-size: 30px;
  }
  @media (max-width: 576px) {
    font-size: 20px;
  }
`;
const TextITSummit = styled.p`
  margin: -8px 0 0 0;
  font-weight: 700;
  color: white;
  font-size: 30px;
  @media (max-width: 992px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 576px) {
    font-size: 20px;
    text-align: center;
  }
`;
const TextNotes = styled.p`
  font-weight: 400;
  color: white;
  font-size: 16px;
  line-height: 1.2;
  max-width: 650px;
  margin: 0 0 0 10px;
  @media (max-width: 992px) {
    font-size: 12px;
    margin: 0;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const TextNotesMobile = styled.p`
  display: none;
  @media (max-width: 768px) {
    font-weight: 400;
    color: white;
    font-size: 11px;
    display: flex;
    margin-top: 20px;
  }
`;
const ImageTitle = styled.img`
  width: 800px;
  height: auto;
  margin-top: -50px;
  margin-left: -10px;
  @media (max-width: 1200px) {
    width: 700px;
  }
  @media (max-width: 992px) {
    width: 400px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const ImageDate = styled.img`
  width: 400px;
  height: auto;
  margin: -70px 0 0 -20px;
  @media (max-width: 1200px) {
    width: 300px;
  }
  @media (max-width: 992px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const WrapLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    align-items: center;
  }
`;
const LogoCTI = styled.img`
  width: 150px;
  height: 150px;
  margin-top: -20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100px;
    height: 100px;
  }
`;
