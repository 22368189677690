import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Color } from "../../config/GlobalRoot";
import { Avatar } from "antd";
import { AiOutlineRight } from "react-icons/ai";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";

const Games = () => {
  const history = useHistory();
  const [mute, setMute] = useState(false);

  return (
    <Root>
      <div style={{ position: "relative" }}>
        <Image
          src="https://event.computradetech.com/images/ITSummit2022/VIDEO/LOOPING_GRADASI/GAMES%20VENUE_GRADASI.mp4"
          autoPlay
          loop
          muted
        />
        <Volume>
          {mute ? (
            <BsFillVolumeMuteFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          ) : (
            <BsFillVolumeUpFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          )}
        </Volume>
        <MenuGames>
          <LogoAngryBird
            src="https://event.computradetech.com/images/ITSummit2022/angry-bg.png"
            alt=""
            onClick={() => history.push("games/1")}
          />
          <LogoFlappy
            src="https://event.computradetech.com/images/ITSummit2022/flappy-bg.png"
            alt=""
            onClick={() => history.push("games/2")}
          />
        </MenuGames>
      </div>
      <MobileMenu onClick={() => history.push("games/1")}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{ backgroundColor: "white" }}
            src="https://event.computradetech.com/images/ITSummit2022/angry-bg.png"
          ></Avatar>{" "}
          <TextMobile>Angry Bird</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu onClick={() => history.push("games/2")}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{ backgroundColor: "white" }}
            src="https://event.computradetech.com/images/ITSummit2022/flappy-bg.png"
          ></Avatar>{" "}
          <TextMobile>Flappy Birds</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <audio autoPlay loop muted={mute}>
        <source
          src="https://event.computradetech.com/images/ITSummit2022/Music/music-lobby-track.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </Root>
  );
};

export default Games;
const Root = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Color.background};
  overflow: hidden;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;
const Image = styled.video`
  width: 100%;
  height: auto;
`;
const MenuGames = styled.div`
  position: absolute;
  width: 800px;
  height: 400px;
  top: 10.5%;
  left: 29%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1600px) {
    width: 750px;
    height: 380px;
  }
  @media (max-width: 1500px) {
    width: 700px;
    height: 360px;
  }
  @media (max-width: 1400px) {
    width: 650px;
    height: 340px;
  }
  @media (max-width: 1300px) {
    width: 600px;
    height: 320px;
  }
  @media (max-width: 1200px) {
    width: 550px;
    height: 300px;
  }
  @media (max-width: 1100px) {
    width: 500px;
    height: 280px;
  }
  @media (max-width: 1000px) {
    width: 450px;
    height: 260px;
  }
  @media (max-width: 900px) {
    width: 400px;
    height: 240px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const MobileMenu = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    background-color: #2f9fae;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid white;
  } ;
`;
const TextMobile = styled.p`
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
`;
const LogoAngryBird = styled.img`
  cursor: pointer;
  width: 200px;
  height: auto;
  @media (max-width: 1300px) {
    width: 150px;
  }
  @media (max-width: 1000px) {
    width: 100px;
  }
`;
const LogoFlappy = styled.img`
  cursor: pointer;
  width: 200px;
  height: auto;
  @media (max-width: 1300px) {
    width: 150px;
  }
  @media (max-width: 1000px) {
    width: 100px;
  }
`;
const Volume = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 15%;
  }
  @media (max-width: 576px) {
    top: 20%;
  }
`;
