import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Avatar } from "antd";
import {
  AiOutlineRight,
  AiFillRocket,
  AiFillBank,
  AiFillCalendar,
  AiFillCompass,
  AiFillQuestionCircle,
} from "react-icons/ai";
import { HiViewGrid } from "react-icons/hi";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";
import { Color } from "../../config/GlobalRoot";

const Lobby = () => {
  const [popUp, setPopup] = useState("");
  const [videoTransisi, setVideoTransisi] = useState("");
  const [mute, setMute] = useState(false);
  const history = useHistory();
  return (
    <Root>
      <div style={{ position: "relative" }}>
        <Image
          src="https://event.computradetech.com/images/ITSummit2022/VIDEO/LOOPING_GRADASI/HOME%20GRADASI%20rev.mp4"
          autoPlay
          loop
          muted
        />
        <Volume>
          {mute ? (
            <BsFillVolumeMuteFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          ) : (
            <BsFillVolumeUpFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          )}
        </Volume>
        <MainHall
          onClick={() => {
            setPopup("Main Hall");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/04_T01_Main_Stage.mp4"
            );
          }}
        ></MainHall>
        <TextMainHall
          onClick={() => {
            setPopup("Main Hall");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/04_T01_Main_Stage.mp4"
            );
          }}
        ></TextMainHall>
        <Agenda
          onClick={() => {
            setPopup("agenda");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/06_T03_AGENDA.mp4"
            );
          }}
        ></Agenda>
        <TextAgenda
          onClick={() => {
            setPopup("agenda");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/06_T03_AGENDA.mp4"
            );
          }}
        ></TextAgenda>
        <Track
          onClick={() => {
            setPopup("track");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05_T02_Track_Session.mp4"
            );
          }}
        ></Track>
        <TextTrack
          onClick={() => {
            setPopup("track");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05_T02_Track_Session.mp4"
            );
          }}
        ></TextTrack>
        <Exhibition
          onClick={() => {
            setPopup("exhibition");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/07_T04_EXPO_rev.mp4"
            );
          }}
        ></Exhibition>
        <TextExhibiton
          onClick={() => {
            setPopup("exhibition");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/07_T04_EXPO_rev.mp4"
            );
          }}
        ></TextExhibiton>
        <Games
          onClick={() => {
            setPopup("games");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/08_TgamesZone.mp4"
            );
          }}
        ></Games>
        <Faq
          onClick={() => {
            setPopup("faq");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/09_TFAQ.mp4"
            );
          }}
        ></Faq>
      </div>

      <MobileMenu
        onClick={() => {
          setPopup("Main Hall");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/04_T01_Main_Stage.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillBank color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>Main Hall</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("exhibition");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/07_T04_EXPO_rev.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillCompass color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>Exhibition</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("track");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05_T02_Track_Session.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <HiViewGrid color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>Track Hall</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("agenda");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/06_T03_AGENDA.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillCalendar color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>Agenda</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("games");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/08_TgamesZone.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillRocket color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>Games Zone</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("faq");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/09_TFAQ.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillQuestionCircle color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>FAQ</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      {popUp.length > 0 && (
        <RootContentPopup>
          <RootVideo>
            <video
              autoPlay
              width="100%"
              height="auto"
              onEnded={() => history.push(`/${popUp}`)}
            >
              <source src={videoTransisi} type="video/mp4" />
            </video>
            <div style={{ bottom: 30, right: 40, position: "absolute" }}>
              <button
                onClick={() => history.push(`/${popUp}`)}
                style={{
                  backgroundColor: "#060e26",
                  padding: 10,
                  color: "white",
                  borderRadius: 5,
                  border: "1px solid #5af2f9",
                }}
              >
                SKIP VIDEO
              </button>
            </div>
          </RootVideo>
        </RootContentPopup>
      )}
      <audio autoPlay loop muted={mute}>
        <source
          src="https://event.computradetech.com/images/ITSummit2022/Music/music-lobby-track.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </Root>
  );
};

export default Lobby;
const Root = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Color.background};
  position: relative;
  overflow: hidden;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;
const MobileMenu = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    background-color: #2f9fae;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid white;
  } ;
`;
const TextMobile = styled.p`
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
`;
const MainHall = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 16.5%;
  left: 2%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-30deg);
  @media (max-width: 1300px) {
    width: 90px;
  }
  @media (max-width: 1200px) {
    width: 80px;
  }
  @media (max-width: 1100px) {
    width: 70px;
    height: 90px;
  }
  @media (max-width: 1000px) {
    width: 60px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 55px;
    height: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TextMainHall = styled.div`
  position: absolute;
  width: 250px;
  height: 30px;
  bottom: 28.5%;
  left: 5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 230px;
  }
  @media (max-width: 1200px) {
    width: 220px;
    height: 25px;
  }
  @media (max-width: 1100px) {
    width: 200px;
    height: 20px;
  }
  @media (max-width: 1000px) {
    width: 180px;
  }
  @media (max-width: 900px) {
    width: 160px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Exhibition = styled.div`
  position: absolute;
  width: 90px;
  height: 100px;
  bottom: 17%;
  right: 6.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(30deg);

  @media (max-width: 1300px) {
    width: 80px;
    height: 90px;
  }
  @media (max-width: 1200px) {
    width: 70px;
    height: 80px;
  }
  @media (max-width: 1100px) {
    width: 60px;
    height: 70px;
  }
  @media (max-width: 1000px) {
    width: 50px;
    height: 60px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 50px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TextExhibiton = styled.div`
  position: absolute;
  width: 150px;
  height: 25px;
  bottom: 28.8%;
  right: 9%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 130px;
  }
  @media (max-width: 1200px) {
    width: 110px;
    height: 20px;
  }
  @media (max-width: 1100px) {
    width: 100px;
    height: 15px;
  }
  @media (max-width: 1000px) {
    width: 90px;
  }
  @media (max-width: 900px) {
    width: 80px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Track = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  bottom: 32%;
  left: 16%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-20deg);
  @media (max-width: 1300px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1200px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1100px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 900px) {
    width: 35px;
    height: 35px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TextTrack = styled.div`
  position: absolute;
  width: 160px;
  height: 25px;
  bottom: 38.5%;
  left: 17.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 140px;
  }
  @media (max-width: 1200px) {
    width: 120px;
    height: 20px;
  }
  @media (max-width: 1100px) {
    width: 110px;
  }
  @media (max-width: 1000px) {
    width: 100px;
    height: 15px;
  }
  @media (max-width: 900px) {
    width: 90px;
    bottom: 37%;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Agenda = styled.div`
  position: absolute;
  width: 60px;
  height: 70px;
  bottom: 30%;
  right: 19%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(30deg);
  @media (max-width: 1300px) {
    width: 65px;
    height: 65px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1000px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TextAgenda = styled.div`
  position: absolute;
  width: 160px;
  height: 25px;
  bottom: 38%;
  right: 20.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 140px;
  }
  @media (max-width: 1200px) {
    width: 120px;
    height: 20px;
  }
  @media (max-width: 1100px) {
    width: 110px;
  }
  @media (max-width: 1000px) {
    width: 100px;
    height: 15px;
  }
  @media (max-width: 900px) {
    width: 90px;
    bottom: 37%;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Faq = styled.div`
  position: absolute;
  width: 160px;
  height: 90px;
  top: 61%;
  right: 32%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 150px;
  }
  @media (max-width: 1200px) {
    width: 140px;
    height: 80px;
  }
  @media (max-width: 1100px) {
    width: 130px;
    height: 70px;
  }
  @media (max-width: 1000px) {
    width: 120px;
    height: 60px;
  }
  @media (max-width: 900px) {
    width: 110px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Games = styled(Faq)`
  left: 30%;
`;

const Image = styled.video`
  width: 100%;
  height: auto;
  max-height: calc(100vh - 50px);
  margin-top: 50px;
  object-fit: cover;
`;
const RootContentPopup = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100% - 50px);
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 3;
  background-color: rgba(45, 111, 155, 0.8);
`;
const RootVideo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;
const Volume = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 15%;
  }
  @media (max-width: 576px) {
    top: 20%;
  }
`;
