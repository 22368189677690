import React from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { AiOutlineArrowLeft } from "react-icons/ai";

const PlayGame = () => {
  const param = useParams();
  const history = useHistory();

  return (
    <GameWrapper>
      <AiOutlineArrowLeft
        style={{
          position: "absolute",
          top: 20,
          left: 30,
          cursor: "pointer",
        }}
        color="#060e26"
        size={30}
        onClick={() => history.push("/games")}
      ></AiOutlineArrowLeft>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <iframe
          width="80%"
          height="80%"
          src={
            param.id === "2"
              ? "https://funhtml5games.com?embed=flappy"
              : "https://funhtml5games.com?embed=angrybirds"
          }
          frameBorder="0"
          title="games"
        ></iframe>
      </div>
    </GameWrapper>
  );
};

export default PlayGame;
const GameWrapper = styled.div`
  margin-top: 50px;
  height: calc(100vh - 50px);
  width: 100%;
  position: relative;
`;
