import React, { useEffect } from "react";
import "./zoom.css";
import { ZoomMtg } from "@zoomus/websdk";
import { API } from "../../config/GlobalRoot";
import getAuthHeader from "../../services/AuthHeader";
const ZoomComponent = ({ data, token }) => {
  const API_KEY = `${data.ZOOM_API_KEY}`;
  const API_SECRET = `${data.ZOOM_API_SECRET}`;
  const MEETING_NUMBER = `${data.ZOOM_MEETING_NUMBER}`;
  const name = `${token.loginName}`;
  const meetConfig = {
    apiKey: API_KEY,
    apiSecret: API_SECRET,
    meetingNumber: MEETING_NUMBER,
    userName: name,
    passWord: `${data.ZOOM_PASSWORD}`,
    leaveUrl: `/zoom/${data.NAME}`,
    role: 0,
  };
  const dataItem = {
    MEETING_NUMBER: MEETING_NUMBER,
    ROLE: 0,
    ZOOM_API_KEY: API_KEY,
    ZOOM_API_SECRET: API_SECRET,
  };
  const launchMeeting = () => {
    API.post("/stage/generatesignaturezoom", dataItem, {
      headers: getAuthHeader(),
    }).then((res) => {
      ZoomMtg.init({
        leaveUrl: meetConfig.leaveUrl,
        screenShare: false,
        disableInvite: true,
        disableRecord: true,
        disablePreview: true,
        meetingInfo: ["topic", "host"],
        success() {
          ZoomMtg.join({
            meetingNumber: meetConfig.meetingNumber,
            userName: meetConfig.userName,
            signature: res.data.result,
            apiKey: meetConfig.apiKey,
            userEmail: token.username,
            passWord: meetConfig.passWord,

            success() {
              // console.log("join meeting success");
            },
            error(res) {
              console.log(res);
            },
          });
        },
        error(res) {
          console.log(res);
        },
      });
    });
  };
  useEffect(() => {
    const zoomRoot = document.getElementById("zmmtg-root");
    zoomRoot.classList.add("show-zoom");
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.1.1/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    launchMeeting();
    return () => {
      zoomRoot.classList.remove("show-zoom");
    };
  }, []);

  return <></>;
};

export default ZoomComponent;
