import React, { useState } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import {
  AiOutlineRight,
  AiFillBank,
  AiFillHome,
  AiFillCalendar,
  AiFillCompass,
} from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { HiViewGrid } from "react-icons/hi";
import { Color } from "../../config/GlobalRoot";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";

const Entrace = () => {
  const [popUp, setPopup] = useState("");
  const [videoTransisi, setVideoTransisi] = useState("");
  const [mute, setMute] = useState(false);
  const history = useHistory();
  return (
    <Root>
      <div style={{ position: "relative" }}>
        <Image
          src="https://event.computradetech.com/images/ITSummit2022/VIDEO/LOOPING_GRADASI/TOWER_GRADASI.mp4"
          autoPlay
          loop
          muted
        />
        <Volume>
          {mute ? (
            <BsFillVolumeMuteFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          ) : (
            <BsFillVolumeUpFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          )}
        </Volume>
        <MainLobby
          onClick={() => {
            setPopup("lobby");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/02_TO_HOME.mp4"
            );
          }}
        ></MainLobby>
        <LabelMainLobby
          onClick={() => {
            setPopup("lobby");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/02_TO_HOME.mp4"
            );
          }}
        ></LabelMainLobby>
        <TrackHall
          onClick={() => {
            setPopup("track");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_TRACK_SESSION.mp4"
            );
          }}
        ></TrackHall>
        <LabelTrack
          onClick={() => {
            setPopup("track");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_TRACK_SESSION.mp4"
            );
          }}
        ></LabelTrack>
        <Agenda
          onClick={() => {
            setPopup("agenda");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_AGENDA.mp4"
            );
          }}
        ></Agenda>
        <LabelAgenda
          onClick={() => {
            setPopup("agenda");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_AGENDA.mp4"
            );
          }}
        ></LabelAgenda>
        <Exhibition
          onClick={() => {
            setPopup("exhibition");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_EXHIBITION_HALL.mp4"
            );
          }}
        ></Exhibition>
        <LabelExhibiton
          onClick={() => {
            setPopup("exhibition");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_EXHIBITION_HALL.mp4"
            );
          }}
        ></LabelExhibiton>
        <MainHall
          onClick={() => {
            setPopup("Main Hall");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_MAIN_STAGE.mp4"
            );
          }}
        ></MainHall>
        <LabelMainHall
          onClick={() => {
            setPopup("Main Hall");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_MAIN_STAGE.mp4"
            );
          }}
        ></LabelMainHall>
      </div>
      <MobileMenu
        onClick={() => {
          setPopup("lobby");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/02_TO_HOME.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillHome color="black" size={30} />
          </Avatar>
          <TextMobile>Main Lobby</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("Main Hall");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_MAIN_STAGE.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillBank color="black" size={30} />
          </Avatar>
          <TextMobile>Main Hall</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("exhibition");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_EXHIBITION_HALL.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillCompass color="black" size={30} />
          </Avatar>
          <TextMobile>Exhibition</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("track");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_TRACK_SESSION.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <HiViewGrid color="black" size={30} />
          </Avatar>
          <TextMobile>Track Hall</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopup("agenda");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/TO_AGENDA.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillCalendar color="black" size={30} />
          </Avatar>
          <TextMobile>Agenda</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>

      {popUp.length > 0 && (
        <RootContentPopup>
          <RootVideo>
            <video
              autoPlay
              width="100%"
              height="auto"
              onEnded={() => history.push(`/${popUp}`)}
            >
              <source src={videoTransisi} type="video/mp4" />
            </video>
            <div
              style={{ bottom: "30px", right: "40px", position: "absolute" }}
            >
              <button
                onClick={() => history.push(`/${popUp}`)}
                style={{
                  backgroundColor: "#060e26",
                  padding: 10,
                  color: "white",
                  borderRadius: 5,
                  border: "1px solid #5af2f9",
                }}
              >
                SKIP VIDEO
              </button>
            </div>
          </RootVideo>
        </RootContentPopup>
      )}
      <audio autoPlay loop muted={mute}>
        <source
          src="https://event.computradetech.com/images/ITSummit2022/Music/music-lobby-track.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </Root>
  );
};

export default Entrace;
const MobileMenu = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 800px) {
    width: 100%;
    background-color: #2f9fae;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid white;
  } ;
`;
const Root = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Color.background};
  position: relative;
  overflow: hidden;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;
const TextMobile = styled.p`
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
`;
const RootVideo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;
const MainLobby = styled.div`
  position: absolute;
  width: 180px;
  height: 340px;
  top: 32%;
  left: 42%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    height: 320px;
    width: 160px;
  }
  @media (max-width: 1200px) {
    height: 300px;
    width: 140px;
  }
  @media (max-width: 1100px) {
    height: 280px;
    width: 130px;
  }
  @media (max-width: 1000px) {
    height: 260px;
    width: 120px;
  }
  @media (max-width: 900px) {
    height: 240px;
    width: 110px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LabelMainLobby = styled.div`
  position: absolute;
  width: 200px;
  height: 70px;
  top: 21%;
  left: 42%;
  /* background-color: rgba(217, 240, 255, 0.6); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 180px;
  }
  @media (max-width: 1200px) {
    width: 160px;
  }
  @media (max-width: 1100px) {
    width: 140px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 120px;
  }
  @media (max-width: 900px) {
    width: 100px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TrackHall = styled.div`
  position: absolute;
  width: 100px;
  height: 200px;
  top: 21%;
  left: 29.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewX(15deg);
  @media (max-width: 1300px) {
    height: 180px;
  }
  @media (max-width: 1200px) {
    height: 160px;
    width: 80px;
  }
  @media (max-width: 1100px) {
    height: 140px;
  }
  @media (max-width: 1000px) {
    height: 120px;
  }
  @media (max-width: 900px) {
    height: 100px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LabelTrack = styled.div`
  position: absolute;
  width: 300px;
  height: 50px;
  top: 30%;
  left: 8%;
  /* background-color: rgba(217, 240, 255, 0.6); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 280px;
  }
  @media (max-width: 1200px) {
    width: 260px;
  }
  @media (max-width: 1100px) {
    width: 240px;
  }
  @media (max-width: 1000px) {
    width: 220px;
  }
  @media (max-width: 900px) {
    width: 200px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Agenda = styled.div`
  position: absolute;
  width: 120px;
  height: 230px;
  top: 17%;
  right: 30.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewX(-15deg);

  @media (max-width: 1300px) {
    height: 210px;
    width: 110px;
  }
  @media (max-width: 1200px) {
    height: 190px;
    width: 100px;
  }
  @media (max-width: 1100px) {
    height: 170px;
    width: 90px;
  }
  @media (max-width: 1000px) {
    height: 170px;
    width: 80px;
  }
  @media (max-width: 900px) {
    height: 150px;
    width: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LabelAgenda = styled.div`
  position: absolute;
  width: 350px;
  height: 50px;
  top: 26%;
  right: 6%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 320px;
  }
  @media (max-width: 1200px) {
    width: 300px;
  }
  @media (max-width: 1100px) {
    width: 280px;
  }
  @media (max-width: 1000px) {
    width: 260px;
  }
  @media (max-width: 900px) {
    width: 240px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Exhibition = styled.div`
  position: absolute;
  width: 80px;
  height: 250px;
  bottom: 17%;
  right: 32.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewX(-15deg);
  @media (max-width: 1300px) {
    height: 230px;
  }
  @media (max-width: 1200px) {
    height: 210px;
    width: 70px;
  }
  @media (max-width: 1100px) {
    height: 190px;
  }
  @media (max-width: 1000px) {
    height: 190px;
  }
  @media (max-width: 900px) {
    height: 170px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LabelExhibiton = styled.div`
  position: absolute;
  width: 400px;
  height: 70px;
  bottom: 18%;
  right: 6%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 380px;
  }
  @media (max-width: 1200px) {
    width: 360px;
    height: 50px;
  }
  @media (max-width: 1100px) {
    width: 340px;
  }
  @media (max-width: 1000px) {
    width: 320px;
  }
  @media (max-width: 900px) {
    width: 300px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const MainHall = styled.div`
  position: absolute;
  width: 100px;
  height: 270px;
  bottom: 16%;
  left: 29%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewX(15deg);
  @media (max-width: 1300px) {
    height: 250px;
  }
  @media (max-width: 1200px) {
    height: 210px;
    width: 90px;
  }
  @media (max-width: 1100px) {
    height: 230px;
    width: 80px;
  }
  @media (max-width: 1000px) {
    height: 190px;
    width: 70px;
  }
  @media (max-width: 900px) {
    height: 170px;
    width: 60px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LabelMainHall = styled.div`
  position: absolute;
  width: 400px;
  height: 60px;
  bottom: 20%;
  left: 2%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 380px;
  }
  @media (max-width: 1200px) {
    width: 360px;
    height: 40px;
  }
  @media (max-width: 1100px) {
    width: 340px;
  }
  @media (max-width: 1000px) {
    width: 320px;
  }
  @media (max-width: 900px) {
    width: 300px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Image = styled.video`
  width: 100%;
  height: auto;
  max-height: calc(100vh - 50px);
  margin-top: 50px;
  object-fit: cover;
`;
const RootContentPopup = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100% - 50px);
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 3;
  background-color: rgba(45, 111, 155, 0.8);
`;
const Volume = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 15%;
  }
  @media (max-width: 576px) {
    top: 20%;
  }
`;
