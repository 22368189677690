import React, { useState, useEffect } from "react";
import Fire from "../../config/Firebase";
import { BsChatDotsFill } from "react-icons/bs";
import LastChat from "./LastChat";
import Chat from "./Chat";
import { AiFillDownCircle } from "react-icons/ai";
import styled from "styled-components";
import getUserToken from "../../services/UserToken";

const BoothChatAllPage = () => {
  const [historyChat, setHistoryChat] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [idBooth, setIdBooth] = useState("");
  const [boothName, setBoothName] = useState("");
  const token = getUserToken();
  const handleDataUserFromLastChat = (data) => {
    setIdBooth(data.id);
    setBoothName(data.name);
  };
  const handleOpenCloseChat = () => {
    setChatOpen(!chatOpen);
    setIdBooth("");
    setBoothName("");
  };
  useEffect(() => {
    Fire.database()
      .ref(`chat/${token.urlname}/HistoryChatBoothUser/${token.id}`)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          const dataSnapshot = snapshot.val();
          const dataHistoryChat = [];
          Object.keys(dataSnapshot).map((key) => {
            dataHistoryChat.push({
              id: key,
              data: dataSnapshot[key],
            });
            return dataSnapshot;
          });
          setHistoryChat(dataHistoryChat);
        }
      });
  }, []);
  const countChatUnread = historyChat.filter(
    (item) => item.data.isRead === 0
  ).length;

  return (
    <>
      <Root active={!chatOpen}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ButtonClose onClick={handleOpenCloseChat} size="30px" />
        </div>
        <BodyChat>
          <RootLastChat>
            <HeaderLastChat>
              <TextLastChat>Recent Chat</TextLastChat>
            </HeaderLastChat>
            {historyChat
              .sort((a, b) => {
                if (a.data.timeChat > b.data.timeChat) return -1;
                else return null;
              })
              .map((chat) => {
                return (
                  <LastChat
                    key={chat.id}
                    name={chat.data.name}
                    lastChat={chat.data.lastChat}
                    time={chat.data.timeChat}
                    isRead={chat.data.isRead}
                    data={chat.data}
                    isActive={chat.data.id === idBooth}
                    idChat={chat.id}
                    sendDataUser={handleDataUserFromLastChat}
                    event={token}
                  />
                );
              })}
          </RootLastChat>
          <RootChatBox>
            {idBooth ? (
              <>
                <HeaderChatBox>
                  <TextNameHeader>{boothName}</TextNameHeader>
                </HeaderChatBox>
                <BodyChatBox>
                  <Chat
                    idReceiver={idBooth}
                    nameUserChat={boothName}
                    token={token}
                  />
                </BodyChatBox>
              </>
            ) : (
              <RootNoBooth>
                <BsChatDotsFill size="4em" color="white" />
                <h5
                  style={{
                    margin: "20px 0",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Select booth to start chat
                </h5>
              </RootNoBooth>
            )}
          </RootChatBox>
        </BodyChat>
      </Root>
      <RootIconChat onClick={handleOpenCloseChat}>
        <ButtonChat>
          <img
            src="https://event.computradetech.com/images/iconchat.png"
            alt=""
            width="100%"
          />
          {countChatUnread > 0 && !chatOpen ? (
            <Badge>{countChatUnread}</Badge>
          ) : null}
        </ButtonChat>
      </RootIconChat>
    </>
  );
};

export default BoothChatAllPage;
const Root = styled.div`
  position: fixed;
  bottom: 85px;
  left: 20px;
  z-index: 16;
  display: ${(props) => props.active && "none"};
  @media (max-width: 576px) {
    bottom: 60px;
    left: 10px;
  } ;
`;
const BodyChat = styled.div`
  display: flex;
  height: 400px;
  width: 400px;
  @media (max-width: 576px) {
    height: 300px;
    width: 300px;
  } ;
`;
const RootLastChat = styled.div`
  width: 150px;
  background-color: white;
  border-radius: 6px 0 0 6px;
  /* border-left: 2px solid #060e26;
  border-top: 2px solid #060e26;
  border-bottom: 2px solid #060e26; */
  overflow: auto;
  @media (max-width: 576px) {
    width: 100px;
  } ;
`;
const HeaderLastChat = styled.div`
  height: 30px;
  border-bottom: 1px solid #060e26;
  padding: 7px;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    height: 20px;
  } ;
`;
const TextLastChat = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 12px;
  } ;
`;
const RootChatBox = styled.div`
  width: 250px;
  @media (max-width: 576px) {
    width: 200px;
  } ;
`;
const HeaderChatBox = styled.div`
  height: 30px;
  background-color: #060e26;
  border-top-right-radius: 6px;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    height: 20px;
  } ;
`;
const TextNameHeader = styled.p`
  color: white;
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
  @media (max-width: 576px) {
    font-size: 12px;
  } ;
`;
const BodyChatBox = styled.div`
  height: 370px;
  /* border-right: 2px solid #060e26; */
  @media (max-width: 576px) {
    height: 280px;
  } ;
`;
const RootNoBooth = styled.div`
  background-color: #060e26;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 6px 6px 0;
`;
const RootIconChat = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  @media (max-width: 576px) {
    bottom: 10;
    right: 10;
  } ;
`;
const ButtonChat = styled.button`
  position: relative;
  width: 150px;
  @media (max-width: 576px) {
    width: 100px;
  } ;
`;
const Badge = styled.span`
  position: absolute;
  top: -20px;
  right: 0;
  background-color: #363740;
  border-radius: 50%;
  color: white;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 576px) {
    height: 25px;
    width: 25px;
    top: -15px;
    right: 0;
  } ;
`;
const ButtonClose = styled(AiFillDownCircle)`
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
  }
`;
