import React from "react";
import moment from "moment";
import Fire from "../../config/Firebase";
import styled from "styled-components";

const LastChat = ({
  data,
  sendDataUser,
  isRead,
  name,
  lastChat,
  time,
  isActive,
  idChat,
  event,
}) => {
  const handleClick = (data) => {
    sendDataUser(data);
    const updateDataIsRead = {
      ...data,
      isRead: 1,
    };
    {
      isRead === 0 &&
        Fire.database()
          .ref(
            `chat/${event.urlname}/HistoryChatBoothUser/${data.idReceiver}/${idChat}`
          )
          .update(updateDataIsRead);
    }
  };
  return (
    <Root active={isActive} onClick={() => handleClick(data)}>
      <div
        style={{
          width: "70%",
        }}
      >
        <TextBooth read={isRead === 1}>{name}</TextBooth>
        <TextChat read={isRead === 1}>{lastChat}</TextChat>
      </div>
      <Time>
        <TextChat read={isRead === 1}>
          {moment(time, "YYYY-MM-DD HH:mm:ss:SS").format("HH:mm")}
        </TextChat>
      </Time>
    </Root>
  );
};

export default LastChat;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.active && "#363740"};
  padding: 7px;
  color: ${(props) => props.active && "white"};
  &:hover {
    cursor: pointer;
  }
`;
const TextBooth = styled.div`
  margin: 0;
  font-size: 12px;
  font-weight: ${(props) => (props.read ? "500" : "700")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 576px) {
    font-size: 9px;
  } ;
`;
const TextChat = styled(TextBooth)`
  font-size: 10px;
  font-weight: ${(props) => (props.read ? "400" : "700")};
`;
const Time = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
`;
