import axios from "axios";

export const RootURL = "http://localhost:3000";
// export const RootAPI = "http://localhost:3003/api";
// export const RootURL = "https://itinfrastructuresummit.computradetech.com";
export const RootAPI = "https://eventapi.computradetech.com/api";
export const API = axios.create({
  baseURL: `${RootAPI}`,
});
export const Color = {
  background: '#02163B'
}