import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "../../config/GlobalRoot";
import getAuthHeader from "../../services/AuthHeader";
import getUserToken from "../../services/UserToken";
import ZoomComponent from "./ZoomComponent";

const Zoom = () => {
  const params = useParams();
  const token = getUserToken();
  const [dataStage, setDataStage] = useState([]);
  const getDataStage = () => {
    API.get(`/stage/listbyeventid/${token.eventid}`, {
      headers: getAuthHeader(),
    })
      .then((res) => {
        setDataStage(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDataStage();
  }, []);
  const dataStageByHall = dataStage.filter(
    (stage) => stage.NAME === params.name
  );
  return (
    <>
      {dataStageByHall.map((stg) => {
        return <ZoomComponent data={stg} key={stg.STAGE_ID} token={token} />;
      })}
    </>
  );
};

export default Zoom;
