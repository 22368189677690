import firebase from "firebase/compat/app";
import "firebase/compat/database";

// var firebaseConfig = {
//   apiKey: "AIzaSyA0H6RTM0KdGOUOUFiJhP2asiKozKQCn-I",
//   authDomain: "iko2021.firebaseapp.com",
//   projectId: "iko2021",
//   storageBucket: "iko2021.appspot.com",
//   messagingSenderId: "1057817230173",
//   appId: "1:1057817230173:web:2cb085fc3edf693fae86b4",
// };
const firebaseConfig = {
  apiKey: "AIzaSyAmAopon306KZah_3rBgxzn4hJCAxVpdLg",
  authDomain: "frontend-iko-2021.firebaseapp.com",
  databaseURL: "https://frontend-iko-2021-default-rtdb.firebaseio.com",
  projectId: "frontend-iko-2021",
  storageBucket: "frontend-iko-2021.appspot.com",
  messagingSenderId: "177274357281",
  appId: "1:177274357281:web:561ec5370b48007f9f8560"
};
firebase.initializeApp(firebaseConfig);

const Fire = firebase;
export default Fire;
