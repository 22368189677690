import CryptoJS from "crypto-js";

let decryptAes, decryptTripleDes;

const getAuthHeader = () => {
  const userToken = JSON.parse(localStorage.getItem('user'));
  if (userToken) {
    decryptTripleDes = CryptoJS.TripleDES.decrypt(
      userToken.accessToken,
      "_k3p0y@?"
    ).toString(CryptoJS.enc.Utf8);
    decryptAes = CryptoJS.AES.decrypt(decryptTripleDes, "_k3p0y@?").toString(
      CryptoJS.enc.Utf8
    );

    return { Authorization: "Bearer " + decryptAes };
  } else {
    return {};
  }
};
export default getAuthHeader;
