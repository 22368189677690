import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Home from "./Home";
import { Avatar, Tooltip } from "antd";
import { FiLogOut } from "react-icons/fi";
import {
  BsFillHouseFill,
  BsChevronDoubleRight,
  BsChevronDoubleLeft,
} from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { API, RootAPI } from "../../config/GlobalRoot";
import styled from "styled-components";
import getBoothToken from "../../services/BoothToken";
import getAuthHeaderAdminBooth from "../../services/AuthHeaderAdminBooth";

const RootBooth = () => {
  const [collapse, setCollapse] = useState(
    window.innerWidth < 768 ? true : false
  );
  const [dataBooth, setDataBooth] = useState([]);
  const ref = useRef();
  const booth = getBoothToken();
  const history = useHistory();
  useEffect(() => {
    let handler = (event) => {
      if (!ref.current.contains(event.target) && window.innerWidth < 768) {
        setCollapse(true);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [ref]);
  useEffect(() => {
    API.get(`/exhibitor/listbyboothid/${booth.id}`, {
      headers: getAuthHeaderAdminBooth(),
    })
      .then((res) => {
        setDataBooth(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logout !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((res) => {
      if (res.isConfirmed) {
        localStorage.removeItem("booth");
        history.push("/login-booth");
      }
    });
  };
  return (
    <Root>
      <RootSidebar collapse={collapse} ref={ref}>
        <div>
          <RootAvatar collapse={collapse}>
            <div className="marginAvatar">
              <Avatar className="avatar">{booth.loginName.charAt(0)}</Avatar>
            </div>
            <p className="textAvatar">{booth.loginName}</p>
          </RootAvatar>
          <div>
            <Tooltip title={collapse ? "Home" : ""} placement="right">
              <LinkMenu collapse={collapse} to="/admin-booth">
                <BsFillHouseFill size="25px" />
                <span className="textMenu">Home</span>
              </LinkMenu>
            </Tooltip>
          </div>
        </div>
        <ArrowCollapse
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {collapse ? (
            <BsChevronDoubleRight size="20px" />
          ) : (
            <BsChevronDoubleLeft size="20px" />
          )}
        </ArrowCollapse>
      </RootSidebar>
      <RootHeader collapse={collapse}>
        <div className="marginHeader">
          <FaBars
            className="burgerBar"
            onClick={() => {
              setCollapse(!collapse);
            }}
            size="20px"
          />
          <img
            src={RootAPI + "/files/booth/" + dataBooth.FOLDER + dataBooth.IMG}
            alt=""
            className="logo"
          />
        </div>
        <Logout onClick={handleLogout}>
          <FiLogOut size="20px" />
          <span className="text">Logout</span>
        </Logout>
      </RootHeader>
      <MainContent collapse={collapse}>
        <Switch>
          <Route path="/admin-booth">
            <Home />
          </Route>
        </Switch>
      </MainContent>
    </Root>
  );
};

export default RootBooth;

const MainContent = styled.div`
  margin-left: ${(props) => (props.collapse ? "80px" : "200px")};
  margin-top: 100px;
  padding: 30px 30px 5px 30px;
  overflow-x: hidden;
  @media (max-width: 576px) {
    margin-left: 0px;
    padding: 20px;
    margin-top: 50px;
  } ;
`;

const Root = styled.div`
  background-color: #f7f8fc;
`;

const RootSidebar = styled.div`
  width: ${(props) => (props.collapse ? "80px" : "200px")};
  background-color: #363740;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 17;
  transition: all 0.5s ease;
  @media (max-width: 576px) {
    margin-left: ${(props) => props.collapse && "-200px"};
  }
`;

const RootAvatar = styled.div`
  display: flex;
  margin: 15px 10px;
  align-items: center;
  justify-content: ${(props) => (props.collapse ? "center" : "start")};
  .marginAvatar {
    margin-right: ${(props) => (props.collapse ? "0px" : "10px")};
  }
  .avatar {
    width: ${(props) => (props.collapse ? "50px" : "60px")};
    height: ${(props) => (props.collapse ? "50px" : "60px")};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #a4a6b3;
    color: white;
  }
  .textAvatar {
    color: #a4a6b3;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    line-height: 1.3;
    word-break: break-all;
    display: ${(props) => props.collapse && "none"};
  }
`;

const RootHeader = styled.div`
  background-color: #f7f8fc;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 16;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  border-bottom: 3px solid #e4e4e4;
  @media (max-width: 576px) {
    padding: 0px 15px;
  }
  .marginHeader {
    margin-left: ${(props) => (props.collapse ? "80px" : "200px")};
    @media (max-width: 576px) {
      margin-left: 0px;
    }
  }
  .burgerBar {
    display: none;
    @media (max-width: 576px) {
      display: inline;
      margin-right: 20px;
      color: #6b6b6b;
    }
  }
  .logo {
    width: 200px;
    height: auto;
    @media (max-width: 768px) {
      width: 150px;
      height: auto;
    }
    @media (max-width: 576px) {
      width: 100px;
      height: auto;
    }
  }
`;

const Logout = styled.div`
  display: flex;
  align-items: center;
  color: #6b6b6b;
  &:hover {
    cursor: pointer;
  }
  .text {
    margin-left: 5px;
    @media (max-width: 576px) {
      display: none;
    }
  }
`;

const ArrowCollapse = styled.div`
  text-align: center;
  padding: 5px;
  color: #a4a6b3;
  margin-top: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const LinkMenu = styled(Link)`
  display: flex;
  color: white;
  background-color: #a4a6b3;
  padding: 10px;
  margin: ${(props) => (props.path ? "0" : "5px 0")};
  justify-content: ${(props) => props.collapse && "center"};
  &:hover {
    background-color: #a4a6b3;
    color: white;
  }
  .textMenu {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    display: ${(props) => props.collapse && "none"};
  }
`;
