import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { AiFillCaretDown, AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
const Navbar = ({ location }) => {
  const [collapse, setCollapse] = useState(false);
  const [toggle, setToggle] = useState("");
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logout !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((res) => {
      if (res.isConfirmed) {
        localStorage.removeItem("user");
        window.location.href = "/login";
      }
    });
  };
  return (
    <Root>
      <img
        src="https://event.computradetech.com/images/ITSummit2022/summitlogo.png"
        alt=""
        width="180"
        height="auto"
        style={{ marginLeft: -10 }}
      />
      <WrapperNavMenu collapse={collapse}>
        <NavMenu
          to="/"
          location={location === "/" ? "red" : "white"}
          onClick={() => setCollapse(false)}
        >
          Entrance
        </NavMenu>
        <NavMenu
          to="/lobby"
          location={location === "/lobby" ? "red" : "white"}
          onClick={() => setCollapse(false)}
        >
          Lobby
        </NavMenu>
        <NavMenu
          to="/Main Hall"
          location={location === "/Main Hall" ? "red" : "white"}
          onClick={() => setCollapse(false)}
        >
          Main Hall
        </NavMenu>
        <Dropdown>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              if (toggle === "track") setToggle("");
              else setToggle("track");
            }}
          >
            <MenuDropdown
              location={
                location === "/track" ||
                location === "/track/Connected Customer" ||
                location === "/track/Connected System" ||
                location === "/track/Connected Workforce"
                  ? "red"
                  : "white"
              }
            >
              Track Hall
            </MenuDropdown>
            <IconDropdown
              size={13}
              location={
                location === "/track" ||
                location === "/track/Connected Customer" ||
                location === "/track/Connected System" ||
                location === "/track/Connected Workforce"
                  ? "red"
                  : "white"
              }
            />
          </div>
          <WrapperDropdown toggle={toggle === "track" ? true : false}>
            <NavMenuDropdown
              to="/track/Connected Customer"
              location={
                location === "/track/Connected Customer" ? "red" : "white"
              }
              onClick={() => setCollapse(false)}
            >
              Connected Customer
            </NavMenuDropdown>
            <NavMenuDropdown
              to="/track/Connected System"
              location={
                location === "/track/Connected System" ? "red" : "white"
              }
              onClick={() => setCollapse(false)}
            >
              Connected System
            </NavMenuDropdown>
            <NavMenuDropdown
              to="/track/Connected Workforce"
              location={
                location === "/track/Connected Workforce" ? "red" : "white"
              }
              onClick={() => setCollapse(false)}
            >
              Connected Workforce
            </NavMenuDropdown>
          </WrapperDropdown>
        </Dropdown>
        <NavMenu
          to="/exhibition"
          location={location === "/exhibition" ? "red" : "white"}
          onClick={() => setCollapse(false)}
        >
          Exhibition
        </NavMenu>
        <NavMenu
          to="/agenda"
          location={location === "/agenda" ? "red" : "white"}
          onClick={() => setCollapse(false)}
        >
          Agenda
        </NavMenu>
        <Dropdown>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              if (toggle === "more") setToggle("");
              else setToggle("more");
            }}
          >
            <MenuDropdown
              location={
                location === "/games" || location === "/faq" ? "red" : "white"
              }
            >
              More
            </MenuDropdown>
            <IconDropdown
              size={13}
              location={
                location === "/games" || location === "/faq" ? "red" : "white"
              }
            />
          </div>
          <WrapperDropdown toggle={toggle === "more" ? true : false}>
            <NavMenuDropdown
              to="/games"
              location={location === "/games" ? "red" : "white"}
              onClick={() => setCollapse(false)}
            >
              Game Zone
            </NavMenuDropdown>
            <NavMenuDropdown
              to="/faq"
              location={location === "/faq" ? "red" : "white"}
              onClick={() => setCollapse(false)}
            >
              FAQ
            </NavMenuDropdown>
          </WrapperDropdown>
        </Dropdown>
        <IconLogout onClick={() => handleLogout()} />
        <ButtonLogoutMobile onClick={() => handleLogout()}>
          Logout
        </ButtonLogoutMobile>
      </WrapperNavMenu>
      {collapse ? (
        <CLoseBar onClick={() => setCollapse(!collapse)} />
      ) : (
        <HamburgerBar onClick={() => setCollapse(!collapse)} />
      )}
    </Root>
  );
};

export default Navbar;
const Root = styled.nav`
  height: 50px;
  background-color: #060e26;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;
const WrapperNavMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 30px;
  @media (max-width: 800px) {
    display: ${(props) => !props.collapse && "none"};
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: calc(100vh - 50px);
    top: 50px;
    left: 0;
    margin-left: 0;
    background-color: #060e26;
    padding: 20px 0 40px 0;
  }
`;
const NavMenu = styled(Link)`
  color: ${(props) =>
    props.location === "red" ? "#53f8fe" : "white"} !important;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: #53f8fe;
  }
`;
const MenuDropdown = styled.span`
  color: ${(props) => (props.location === "red" ? "#53f8fe" : "white")};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  &:hover {
    color: #53f8fe;
  }
`;
const NavMenuDropdown = styled(Link)`
  color: ${(props) => (props.location === "red" ? "white" : "black")};
  background-color: ${(props) =>
    props.location === "red" ? "#2f9fae" : "white"};
  cursor: pointer;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    color: white;
    background-color: #2f9fae;
  }
  @media (max-width: 800px) {
    text-align: center;
    color: ${(props) => (props.location === "red" ? "#53f8fe" : "white")};
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    background-color: transparent;
    &:hover {
      color: #5af2f9;
      background-color: transparent;
    }
  }
`;

const HamburgerBar = styled(FaBars)`
  display: none;
  color: white;
  @media (max-width: 800px) {
    display: block;
  }
  &:hover ${WrapperNavMenu} {
    background-color: #5af2f9;
  }
`;
const CLoseBar = styled(AiOutlineClose)`
  display: none;
  color: white;
  @media (max-width: 800px) {
    display: block;
  }
  &:hover ${WrapperNavMenu} {
    background-color: #5af2f9;
  }
`;

const IconDropdown = styled(AiFillCaretDown)`
  color: ${(props) => (props.location === "red" ? "#53f8fe" : "white")};
  margin-left: 2px;
  cursor: pointer;
`;
const WrapperDropdown = styled.div`
  position: absolute;
  background-color: white;
  min-width: 100px;
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    left: 0;
    background-color: transparent;
    display: ${(props) => (props.toggle ? "flex" : "none")};
    flex-direction: column;
    position: relative;
  }
`;
const Dropdown = styled.div`
  @media (min-width: 800px) {
    &:hover ${WrapperDropdown} {
      display: flex;
      flex-direction: column;
    }
  }
  &:hover ${MenuDropdown} {
    color: #5af2f9;
  }
  &:hover ${IconDropdown} {
    color: #5af2f9;
  }
`;

const ButtonLogoutMobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 120px;
    height: 40px;
    background-color: #2f9fae;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
`;
const IconLogout = styled(FiLogOut)`
  color: white;
  cursor: pointer;
  @media (max-width: 800px) {
    display: none;
  }
  &:hover {
    color: #53f8fe;
  }
`;
