import CryptoJS from "crypto-js";

let decryptAes, decryptTripleDes;

const getAuthHeaderAdminBooth = () => {
  const adminBoothToken = JSON.parse(localStorage.getItem("booth"));
  if (adminBoothToken) {
    decryptTripleDes = CryptoJS.TripleDES.decrypt(
      adminBoothToken.accessToken,
      "_k3p0y@?"
    ).toString(CryptoJS.enc.Utf8);
    decryptAes = CryptoJS.AES.decrypt(decryptTripleDes, "_k3p0y@?").toString(
      CryptoJS.enc.Utf8
    );

    return { Authorization: "Bearer " + decryptAes };
  } else {
    return {};
  }
};
export default getAuthHeaderAdminBooth;
