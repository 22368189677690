import React from "react";
import { Route } from "react-router-dom";
import getBoothToken from "./BoothToken";

const ProtectedRouteBooth = ({ component: Component, ...rest }) => {
  const token = getBoothToken();
  const now = new Date();
  const exp = new Date(token.exp * 1000);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (now <= exp) {
          return <Component {...props} />;
        } else {
          localStorage.removeItem("booth");
          window.location.href = "/login-booth";
        }
      }}
    />
  );
};

export default ProtectedRouteBooth;
