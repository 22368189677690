import React from "react";
import FormLoginBooth from "../../components/FormLogin/FormLoginBooth";
import styled from "styled-components";

const LoginBooth = () => {
  return (
    <Root>
      <img
        src="https://event.computradetech.com/images/iko21/CTIGroup.png"
        alt=""
        width="150"
        height="150"
      />
      <TextWelcome> Login Admin Booth</TextWelcome>
      <Form>
        <FormLoginBooth />
      </Form>
    </Root>
  );
};

export default LoginBooth;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://event.computradetech.com/images/bglogin.jpg");
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
`;
const TextWelcome = styled.p`
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #697687;
  @media (max-width: 576px) {
    font-size: 20;
  }
`;
const Form = styled.div`
  background-color: white;
  padding: 10px 20px;
  width: 500px;
  height: 300px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (max-width: 576px) {
    width: 90%;
  } ;
`;
