import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { API, Color, RootAPI } from "../../config/GlobalRoot";
import getAuthHeader from "../../services/AuthHeader";
import getUserToken from "../../services/UserToken";
import BoothChatAllPage from "../../components/BoothChat/BoothChatAllPage";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";

const Exhibition = () => {
  const history = useHistory();
  const token = getUserToken();
  const [dataBooth, setDataBooth] = useState([]);
  const [mute, setMute] = useState(false);
  useEffect(() => {
    API.get(`/exhibitor/listbyeventid/${token.eventid}`, {
      headers: getAuthHeader(),
    })
      .then((res) => {
        setDataBooth(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleCLickBooth = (id) => {
    const dataLogVisit = {
      EVENT_ID: token.eventid,
      USER_ID: token.id,
      BOOTH_ID: id,
      ACTIVITY: "VISIT",
    };

    API.post("/activitylog/boothlog", dataLogVisit, {
      headers: getAuthHeader(),
    }).then(() => history.push(`/exhibition/${id}`));
  };
  return (
    <Root>
      <div style={{ position: "relative" }}>
        <Image
          src="https://event.computradetech.com/images/ITSummit2022/VIDEO/LOOPING_GRADASI/EXPO_GRADASI%20rev.mp4"
          autoPlay
          loop
          muted
        />
        <Volume>
          {mute ? (
            <BsFillVolumeMuteFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          ) : (
            <BsFillVolumeUpFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          )}
        </Volume>
        <Platinum1 onClick={() => handleCLickBooth(1)}></Platinum1>
        <LogoPlatinum1 onClick={() => handleCLickBooth(1)}></LogoPlatinum1>
        <Platinum2 onClick={() => handleCLickBooth(10)}></Platinum2>
        <LogoPlatinum2 onClick={() => handleCLickBooth(10)}></LogoPlatinum2>
        <Gold1 onClick={() => handleCLickBooth(2)}></Gold1>
        <LogoGold1 onClick={() => handleCLickBooth(2)}></LogoGold1>
        <Gold2 onClick={() => handleCLickBooth(3)}></Gold2>
        <LogoGold2 onClick={() => handleCLickBooth(3)}></LogoGold2>
        <Gold3 onClick={() => handleCLickBooth(4)}></Gold3>
        <LogoGold3 onClick={() => handleCLickBooth(4)}></LogoGold3>
        <Gold4 onClick={() => handleCLickBooth(5)}></Gold4>
        <LogoGold4 onClick={() => handleCLickBooth(5)}></LogoGold4>
        <Gold5 onClick={() => handleCLickBooth(6)}></Gold5>
        <LogoGold5 onClick={() => handleCLickBooth(6)}></LogoGold5>
        <Gold6 onClick={() => handleCLickBooth(7)}></Gold6>
        <LogoGold6 onClick={() => handleCLickBooth(7)}></LogoGold6>
        <Gold7 onClick={() => handleCLickBooth(8)}></Gold7>
        <LogoGold7 onClick={() => handleCLickBooth(8)}></LogoGold7>
        <Gold8 onClick={() => handleCLickBooth(9)}></Gold8>
        <LogoGold8 onClick={() => handleCLickBooth(9)}></LogoGold8>
      </div>
      {dataBooth.map((booth) => {
        return (
          <MobileMenu
            onClick={() => handleCLickBooth(booth.SORT)}
            key={booth.BOOTH_ID}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={RootAPI + "/files/booth/" + booth.FOLDER + booth.IMG}
                alt="logo"
                width="60"
                height="auto"
              />
            </div>
            <AiOutlineRight />
          </MobileMenu>
        );
      })}
      <BoothChatAllPage />
      <audio autoPlay loop muted={mute}>
        <source
          src="https://event.computradetech.com/images/ITSummit2022/Music/music-lobby-track.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </Root>
  );
};

export default Exhibition;
const Root = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: ${Color.background};
  overflow: hidden;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;
const MobileMenu = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    background-color: #2f9fae;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid white;
  } ;
`;
const Image = styled.video`
  width: 100%;
  height: auto;
  max-height: calc(100vh - 50px);
  margin-top: 50px;
  object-fit: cover;
`;
const Platinum1 = styled.div`
  position: absolute;
  width: 280px;
  height: 190px;
  bottom: 21%;
  left: 20%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(10deg);
  border-top-left-radius: 80px;
  @media (max-width: 1300px) {
    width: 250px;
    height: 180px;
  }
  @media (max-width: 1200px) {
    width: 220px;
    height: 170px;
  }
  @media (max-width: 1100px) {
    width: 190px;
    height: 160px;
  }
  @media (max-width: 1000px) {
    width: 160px;
    height: 150px;
  }
  @media (max-width: 900px) {
    width: 130px;
    height: 140px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoPlatinum1 = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  bottom: 34%;
  left: 27.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 110px;
    height: 110px;
  }
  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 1100px) {
    width: 90px;
    height: 90px;
  }
  @media (max-width: 1000px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Platinum2 = styled.div`
  position: absolute;
  width: 280px;
  height: 190px;
  bottom: 21%;
  right: 19%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-2deg);
  border-top-right-radius: 80px;
  @media (max-width: 1300px) {
    width: 290px;
    height: 190px;
  }
  @media (max-width: 1200px) {
    width: 260px;
    height: 180px;
  }
  @media (max-width: 1100px) {
    width: 230px;
    height: 170px;
  }
  @media (max-width: 1000px) {
    width: 210px;
    height: 150px;
  }
  @media (max-width: 900px) {
    width: 180px;
    height: 140px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoPlatinum2 = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  bottom: 34%;
  right: 26%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 110px;
    height: 110px;
  }
  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 1100px) {
    width: 90px;
    height: 90px;
  }
  @media (max-width: 1000px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold1 = styled.div`
  position: absolute;
  width: 150px;
  height: 90px;
  bottom: 32.5%;
  left: 3%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-5deg);
  @media (max-width: 1300px) {
    width: 160px;
    height: 100px;
  }
  @media (max-width: 1200px) {
    width: 140px;
    height: 90px;
  }
  @media (max-width: 1100px) {
    width: 120px;
    height: 70px;
  }
  @media (max-width: 1000px) {
    width: 110px;
    height: 60px;
  }
  @media (max-width: 900px) {
    width: 100px;
    height: 50px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold1 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  bottom: 41%;
  left: 5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
    left: 6%;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold2 = styled.div`
  position: absolute;
  width: 120px;
  height: 70px;
  bottom: 44%;
  left: 12%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-3deg);
  @media (max-width: 1300px) {
    width: 120px;
    height: 75px;
  }
  @media (max-width: 1200px) {
    width: 110px;
    height: 70px;
  }
  @media (max-width: 1100px) {
    width: 100px;
    height: 65px;
  }
  @media (max-width: 1000px) {
    width: 90px;
    height: 55px;
  }
  @media (max-width: 900px) {
    width: 80px;
    height: 45px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold2 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 39%;
  left: 13%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold3 = styled.div`
  position: absolute;
  width: 100px;
  height: 60px;
  top: 43%;
  left: 25.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-3deg);
  @media (max-width: 1300px) {
    width: 100px;
    height: 55px;
  }
  @media (max-width: 1200px) {
    width: 90px;
    height: 50px;
  }
  @media (max-width: 1100px) {
    width: 80px;
    height: 45px;
  }
  @media (max-width: 1000px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 900px) {
    width: 60px;
    height: 35px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold3 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 35%;
  left: 26%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold4 = styled.div`
  position: absolute;
  width: 100px;
  height: 50px;
  top: 41%;
  left: 38%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 100px;
    height: 55px;
  }
  @media (max-width: 1200px) {
    width: 90px;
    height: 50px;
  }
  @media (max-width: 1100px) {
    width: 80px;
    height: 45px;
  }
  @media (max-width: 1000px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 900px) {
    width: 60px;
    height: 35px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold4 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 32%;
  left: 39%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold5 = styled.div`
  position: absolute;
  width: 100px;
  height: 50px;
  top: 41%;
  right: 38%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 100px;
    height: 55px;
  }
  @media (max-width: 1200px) {
    width: 90px;
    height: 50px;
  }
  @media (max-width: 1100px) {
    width: 80px;
    height: 45px;
  }
  @media (max-width: 1000px) {
    width: 70px;
    height: 40px;
  }
  @media (max-width: 900px) {
    width: 60px;
    height: 35px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold5 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 32%;
  right: 39%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold6 = styled.div`
  position: absolute;
  width: 100px;
  height: 60px;
  top: 43%;
  right: 25.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(3deg);
  @media (max-width: 1300px) {
    width: 100px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 90px;
    height: 65px;
  }
  @media (max-width: 1100px) {
    width: 80px;
    height: 60px;
  }
  @media (max-width: 1000px) {
    width: 70px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 60px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold6 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 35%;
  right: 26%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold7 = styled.div`
  position: absolute;
  width: 120px;
  height: 70px;
  bottom: 44%;
  right: 12%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(3deg);
  @media (max-width: 1300px) {
    width: 120px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 110px;
    height: 65px;
  }
  @media (max-width: 1100px) {
    width: 100px;
    height: 60px;
  }
  @media (max-width: 1000px) {
    width: 90px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 80px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold7 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 39%;
  right: 14%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Gold8 = styled.div`
  position: absolute;
  width: 150px;
  height: 90px;
  bottom: 32.5%;
  right: 3%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(5deg);
  @media (max-width: 1300px) {
    width: 160px;
    height: 90px;
  }
  @media (max-width: 1200px) {
    width: 150px;
    height: 85px;
  }
  @media (max-width: 1100px) {
    width: 140px;
    height: 80px;
  }
  @media (max-width: 1000px) {
    width: 120px;
    height: 70px;
  }
  @media (max-width: 900px) {
    width: 110px;
    height: 60px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold8 = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  bottom: 41%;
  right: 5.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 1300px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Volume = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 15%;
  }
  @media (max-width: 576px) {
    top: 20%;
  }
`;
