import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './pages/User/Login';
import NotFound from './pages/User/NotFound';
import Zoom from './components/Zoom';
import ProtectedRouteUser from './services/ProtectedRouteUser';
import LoginBooth from './pages/AdminBooth/Login';
import ProtectedRouteBooth from './services/ProtectedRouteBooth';
import RootBooth from './pages/AdminBooth/RootBooth';
import RootUser from './pages/User/RootUser';
const App = () => {
  return (
     <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/login-booth" component={LoginBooth} />
        <ProtectedRouteBooth path="/admin-booth" component={RootBooth} />
        <ProtectedRouteUser path="/zoom/:name" component={Zoom} />
        <ProtectedRouteUser path="/" component={RootUser} />
      </Switch>
    </Router>

  )
}

export default App
