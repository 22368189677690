import React from "react";
import moment from "moment";
import styled from "styled-components";

const IsMe = ({ text, time }) => {
  return (
    <Root>
      <Chat>
        <TextChat>{text}</TextChat>
      </Chat>
      <TextTime>
        {moment(time, "YYYY-MM-DD HH:mm:ss:SS").format("HH:mm")}
      </TextTime>
    </Root>
  );
};

export default IsMe;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 15px;
  padding-right: 10px;
  @media (max-width: 576px) {
    padding-right: 5px;
  } ;
`;
const Chat = styled.div`
  background-color: #d5f9ba;
  padding: 10px;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  max-width: 85%;
  word-break: break-all;
`;
const TextChat = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 576px) {
    font-size: 12px;
  } ;
`;
const TextTime = styled(TextChat)`
  font-size: 12px;
  @media (max-width: 576px) {
    font-size: 10px;
  } ;
`;
