import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";

let decoded, decryptAes, decryptTripleDes;

const getBoothToken = () => {
  const userToken = JSON.parse(localStorage.getItem("booth"));

  if (userToken) {
    decryptTripleDes = CryptoJS.TripleDES.decrypt(
      userToken.accessToken,
      "_k3p0y@?"
    ).toString(CryptoJS.enc.Utf8);
    decryptAes = CryptoJS.AES.decrypt(decryptTripleDes, "_k3p0y@?").toString(
      CryptoJS.enc.Utf8
    );

    decoded = jwt_decode(decryptAes);
    return decoded;
  } else {
    return {};
  }
};
export default getBoothToken;
