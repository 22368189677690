import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const TrackHall = () => {
  const param = useParams();
  const history = useHistory();
  return (
    <>
      <ZoomWrapper>
        <AiOutlineArrowLeft
          style={{ position: "absolute", top: 20, left: 30, cursor: "pointer" }}
          color="#2f9fae"
          size={30}
          onClick={() => history.push("/track")}
        ></AiOutlineArrowLeft>
        <iframe
          width="100%"
          height="100%"
          src={`/zoom/${param.name}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="zoom"
        ></iframe>
      </ZoomWrapper>
    </>
  );
};

export default TrackHall;
const ZoomWrapper = styled.div`
  margin-top: 50px;
  height: calc(100vh - 50px);
  width: 100%;
  position: relative;
`;
