import React from "react";
import moment from "moment";
import Fire from "../../config/Firebase";
import getBoothToken from "../../services/BoothToken";
import styled from "styled-components";

const LastChat = ({
  data,
  sendDataUser,
  isRead,
  name,
  lastChat,
  time,
  isActive,
  idChat,
}) => {
  const token = getBoothToken();
  const handleClick = (data) => {
    sendDataUser(data);
    const updateDataIsRead = {
      ...data,
      isRead: 1,
    };
    {
      isRead === 0 &&
        Fire.database()
          .ref(
            `chat/${token.urlname}/HistoryChatBoothAdmin/${data.idReceiver}/${idChat}`
          )
          .update(updateDataIsRead);
    }
  };
  return (
    <Root active={isActive} onClick={() => handleClick(data)}>
      <div style={{ width: "70%" }}>
        <Text read={isRead === 1}>{name}</Text>
        <Text read={isRead === 1}>{lastChat}</Text>
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Text read={isRead === 1}>
          {moment(time, "YYYY-MM-DD HH:mm:ss:SS").format("HH:mm")}
        </Text>
      </div>
    </Root>
  );
};

export default LastChat;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.active ? "#A4A6B3" : "white")};
  padding: 7px;
  color: ${(props) => (props.active ? "white" : "#6b6b6b")};
  &:hover {
    cursor: pointer;
  }
`;
const Text = styled.div`
  margin: 0;
  font-size: 15px;
  font-weight: ${(props) => (props.read ? "500" : "700")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 576px) {
    font-size: 9px;
  } ;
`;
