import React, { useState, useEffect } from "react";
import Fire from "../../config/Firebase";
import { BsChatDotsFill } from "react-icons/bs";
import LastChat from "./LastChat";
import AdminChat from "./AdminChat";
import styled from "styled-components";
import getBoothToken from "../../services/BoothToken";

const BoothAdminChat = () => {
  const [historyChat, setHistoryChat] = useState([]);
  const [idUserChat, setIdUserChat] = useState("");
  const [nameUserChat, setNameUserChat] = useState("");
  const token = getBoothToken();

  const handleDataUserFromLastChat = (data) => {
    setNameUserChat(data.name);
    setIdUserChat(data.id);
  };
  const countChatUnread = historyChat.filter(
    (item) => item.data.isRead === 0
  ).length;
  useEffect(() => {
    Fire.database()
      .ref(`chat/${token.urlname}/HistoryChatBoothAdmin/${token.id}`)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          const dataSnapshot = snapshot.val();
          const dataHistoryChat = [];
          Object.keys(dataSnapshot).map((key) => {
            dataHistoryChat.push({
              id: key,
              data: dataSnapshot[key],
            });
            return dataSnapshot;
          });
          setHistoryChat(dataHistoryChat);
        }
      });
  }, []);
  return (
    <Root>
      <RootLastChat>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <TextLastChat>Recent Chat</TextLastChat>
          {countChatUnread > 0 ? (
            <ButtonNewChat>{countChatUnread} New Chat</ButtonNewChat>
          ) : null}
        </div>

        {historyChat
          .sort((a, b) => {
            if (a.data.timeChat > b.data.timeChat) return -1;
            else return null;
          })
          .map((chat) => {
            return (
              <LastChat
                key={chat.id}
                name={chat.data.name}
                lastChat={chat.data.lastChat}
                time={chat.data.timeChat}
                isRead={chat.data.isRead}
                data={chat.data}
                isActive={chat.data.id === idUserChat}
                idChat={chat.id}
                sendDataUser={handleDataUserFromLastChat}
              />
            );
          })}
      </RootLastChat>
      <div style={{ width: "70%" }}>
        {idUserChat ? (
          <>
            <HeaderChat>
              <p style={{ color: "white", margin: "0 0 0 10px" }}>
                {nameUserChat}
              </p>
            </HeaderChat>
            <div style={{ height: "95%" }}>
              <AdminChat idReceiver={idUserChat} nameUserChat={nameUserChat} />
            </div>
          </>
        ) : (
          <RootNouser>
            <BsChatDotsFill size="5em" color="white" />
            <h4 style={{ margin: "20px 0", color: "white" }}>
              Select user to start chat
            </h4>
          </RootNouser>
        )}
      </div>
    </Root>
  );
};

export default BoothAdminChat;

const Root = styled.div`
  height: 80vh;
  display: flex;
`;
const RootLastChat = styled.div`
  width: 30%;
  background-color: #dfe0eb;
  padding: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 10px;
  overflow: auto;
  @media (max-width: 576px) {
    padding: 10px;
  } ;
`;
const TextLastChat = styled.h4`
  color: #6b6b6b;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 14px;
  } ;
`;
const ButtonNewChat = styled.button`
  padding: 8px;
  background-color: #0170fe;
  border-radius: 5px;
  color: white;
`;
const RootNouser = styled.div`
  background-color: #363740;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
`;
const HeaderChat = styled.div`
  height: 5%;
  background-color: #363740;
  display: flex;
  align-items: center;
  border-top-right-radius: 10px;
`;
