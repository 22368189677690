import React, { useEffect } from "react";
import { notification } from "antd";
import Fire from "../../config/Firebase";

const Notification = () => {
  const openNotification = (item) => {
    notification.info({
      message: "Notification",
      description: item.text,
      duration: 5,
    });
  };
  useEffect(() => {
    Fire.database()
      .ref(`/notification/50`)
      .on("value", (snapshot) => {
        if (snapshot.val()) {
          openNotification(snapshot.val());
        }
      });
  }, []);

  return <></>;
};

export default Notification;
