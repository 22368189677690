import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import getUserToken from "../../services/UserToken";
import getAuthHeader from "../../services/AuthHeader";
import { API, Color, RootAPI } from "../../config/GlobalRoot";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
const Schedule = () => {
  const token = getUserToken();
  const [dataSchedule, setDataSchedule] = useState([]);
  const [mute, setMute] = useState(false);
  const [popUp, setPopUp] = useState("");
  useEffect(() => {
    API.get(`/lineup/listschedule/${token.eventid}`, {
      headers: getAuthHeader(),
    })
      .then((res) => {
        setDataSchedule(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Root>
      <div style={{ position: "relative" }}>
        <Image
          src="https://event.computradetech.com/images/ITSummit2022/VIDEO/LOOPING_GRADASI/09A%20FAQ%20GRADASI.mp4"
          autoPlay
          loop
          muted
        />

        <WrapRoot>
          <WrapperSchedule>
            {dataSchedule.map((sch) => {
              return (
                <WrapperContent key={sch.SCHEDULE_ID}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <WrapTime>
                      <Time>
                        {sch.START_TIME} - {sch.END_TIME}
                      </Time>
                    </WrapTime>
                    <WrapBadge>
                      <Badge>{sch.STAGE}</Badge>
                    </WrapBadge>
                  </div>

                  <Title>{sch.TITLE}</Title>
                  <WrapperSpeaker>
                    {sch.SPEAKER.map((spk) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            margin: "0 20px 10px 0",
                            width: 300,
                            cursor: "pointer",
                          }}
                          key={spk.SPEAKER_ID}
                          onClick={() => setPopUp(spk)}
                        >
                          <div style={{ marginRight: 10 }}>
                            <Avatar
                              src={
                                RootAPI +
                                "/files/speaker/" +
                                spk.FOLDER +
                                spk.IMG
                              }
                            />
                          </div>

                          <div>
                            <SpeakerName>{spk.NAME}</SpeakerName>
                            <SpeakeTitle>
                              {spk.TITLE} at {spk.COMPANY}
                            </SpeakeTitle>
                          </div>
                        </div>
                      );
                    })}
                  </WrapperSpeaker>
                </WrapperContent>
              );
            })}
          </WrapperSchedule>
        </WrapRoot>
        <Volume>
          {mute ? (
            <BsFillVolumeMuteFill size={30} onClick={() => setMute(!mute)} />
          ) : (
            <BsFillVolumeUpFill size={30} onClick={() => setMute(!mute)} />
          )}
        </Volume>
      </div>
      {popUp && (
        <PopupAgenda>
          <RootPopUp>
            <AiFillCloseCircle
              size={30}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: -10,
                right: -10,
              }}
              onClick={() => setPopUp("")}
            ></AiFillCloseCircle>
            <div style={{ height: "100%", overflow: "auto", padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <Avatar
                    src={RootAPI + "/files/speaker/" + popUp.FOLDER + popUp.IMG}
                  />
                </div>

                <div>
                  <SpeakerName>{popUp.NAME}</SpeakerName>
                  <SpeakeTitle>
                    {popUp.TITLE} at {popUp.COMPANY}
                  </SpeakeTitle>
                </div>
              </div>
              <p>{popUp.DESCRIPTION}</p>
            </div>
          </RootPopUp>
        </PopupAgenda>
      )}

      <audio autoPlay loop muted={mute}>
        <source
          src="https://event.computradetech.com/images/ITSummit2022/Music/music-lobby-track.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </Root>
  );
};

export default Schedule;
const Root = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${Color.background};
  overflow: hidden;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;
const WrapperSchedule = styled.div`
  background-color: rgba(236, 236, 236, 0.8);
  width: 50%;
  height: 80%;
  overflow: auto;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 576px) {
    width: 80%;
    padding: 10px;
  }
`;
const Time = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;
const WrapTime = styled.div`
  border-radius: 5px;
  padding: 5px;
  background-color: grey;
  color: white;
  display: flex;
  justify-content: center;
`;
const Badge = styled(Time)`
  font-size: 10px;
`;
const WrapBadge = styled(WrapTime)`
  background-color: #060e26;
`;
const Title = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
`;
const WrapperContent = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid grey;
  @media (max-width: 576px) {
    margin-bottom: 15px;
  }
`;
const WrapperSpeaker = styled.div`
  display: flex;
  margin: 10px 0;
  flex-flow: wrap;
`;
const SpeakerName = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
`;
const SpeakeTitle = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
`;
const WrapRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  position: absolute;
  top: 0;
  width: 100%;
  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;
const Image = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
const Volume = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 15%;
  }
  @media (max-width: 576px) {
    top: 20%;
  }
`;
const PopupAgenda = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 50px);
  top: 50px;
  background-color: rgba(217, 240, 255, 0.9);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
`;
const RootPopUp = styled.div`
  width: 50%;
  height: 50%;
  background-color: white;
  position: relative;
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 80%;
    height: 80%;
  }
`;
