import React from "react";
import { Route } from "react-router-dom";
import getUserToken from "./UserToken";

const ProtectedRouteUser = ({ component: Component, ...rest }) => {
  const token = getUserToken();
  const now = new Date();
  const exp = new Date(token.exp * 1000);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (now <= exp) {
          return <Component {...props} />;
        } else {
          localStorage.removeItem("user");
          window.location.href = "/login";
        }
      }}
    />
  );
};

export default ProtectedRouteUser;
