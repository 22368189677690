import React from "react";
import styled from "styled-components";

const MainHall = () => {
  return (
    <ZoomWrapper>
      <iframe
        width="100%"
        height="100%"
        src="/zoom/Main Hall"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="zoom"
      ></iframe>
    </ZoomWrapper>
  );
};

export default MainHall;
const ZoomWrapper = styled.div`
  margin-top: 50px;
  height: calc(100vh - 50px);
  width: 100%;
`;
