import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { API, Color, RootAPI } from "../../config/GlobalRoot";
import getUserToken from "../../services/UserToken";
import getAuthHeader from "../../services/AuthHeader";
import { useHistory, useParams } from "react-router-dom";
import {
  AiFillCloseCircle,
  AiOutlineDownload,
  AiOutlineRight,
  AiOutlineArrowLeft,
  AiOutlineLeft,
  AiOutlineGlobal,
  AiOutlineCloudDownload,
  AiOutlineVideoCamera,
  AiOutlineWechat,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import BoothChat from "../../components/BoothChat";
import { Avatar } from "antd";
const Booth = () => {
  const [dataBooth, setDataBooth] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [popUp, setPopUp] = useState("");
  const [idBooth, setIdBooth] = useState("");
  const [boothName, setBoothName] = useState("");
  const params = useParams();
  const token = getUserToken();
  const history = useHistory();
  useEffect(() => {
    API.get(`/exhibitor/listbyeventid/${token.eventid}`, {
      headers: getAuthHeader(),
    })
      .then((res) => {
        setDataBooth(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleBooth = (data) => {
    setIdBooth(data.id);
    setBoothName(data.name);
  };
  const handleOpenCloseChat = (data) => {
    // setIdBooth("");
    // setBoothName("");
    setChatOpen(data);
  };
  const boothById = dataBooth?.filter((b) => b.SORT == params.id);
  const goBack = (id) => {
    const newId = parseInt(id) - 1;
    if (params.id === "1") {
      history.push("10");
    } else {
      history.push(`${newId}`);
    }
  };
  const goNext = (id) => {
    const newId = parseInt(id) + 1;
    if (params.id === "10") {
      history.push("1");
    } else {
      history.push(`${newId}`);
    }
  };
  return (
    <Root>
      {boothById.map((b) => {
        if (b.NAME === "Cloudflare") {
          return (
            <div style={{ position: "relative" }} key={b.BOOTH_ID}>
              <Image src={RootAPI + "/files/booth/" + b.FOLDER + b.BANNER} />
              <ArrowBack
                size={30}
                onClick={() => history.push("/exhibition")}
              ></ArrowBack>
              <ArrowLeft
                size={30}
                onClick={() => goBack(params.id)}
              ></ArrowLeft>
              <ArrowRight
                size={30}
                onClick={() => goNext(params.id)}
              ></ArrowRight>
              <LogoCloudFlare
                href={b.LINK}
                target="_blank"
                rel="noopener noreferrer"
              ></LogoCloudFlare>
              <LogoCloudFlare2
                href={b.LINK}
                target="_blank"
                rel="noopener noreferrer"
              ></LogoCloudFlare2>

              <VideoCLoudFlare
                onClick={() => setPopUp("video")}
              ></VideoCLoudFlare>

              <AttachmentCloudFlare
                onClick={() => {
                  setPopUp("attachment");
                  setChatOpen(false);
                }}
              ></AttachmentCloudFlare>
              <ChatCloudFlare
                onClick={() => {
                  setChatOpen(true);
                  setIdBooth(b.BOOTH_ID);
                  setBoothName(b.NAME);
                }}
              ></ChatCloudFlare>
              <QuestionaireCLoudFlare
                href={b.QUESTIONNAIRE}
                target="_blank"
                rel="noopener noreferrer"
              ></QuestionaireCLoudFlare>
              <BoothChat
                data={b}
                token={token}
                chatOpen={chatOpen}
                idUserChat={idBooth}
                nameUserChat={boothName}
                handleUser={handleBooth}
                handleOpenCloseChat={handleOpenCloseChat}
              />
            </div>
          );
        } else if (b.NAME === "Veeam") {
          return (
            <div style={{ position: "relative" }} key={b.BOOTH_ID}>
              <Image src={RootAPI + "/files/booth/" + b.FOLDER + b.BANNER} />
              <ArrowBack
                size={30}
                onClick={() => history.push("/exhibition")}
              ></ArrowBack>
              <ArrowLeft
                size={30}
                onClick={() => goBack(params.id)}
              ></ArrowLeft>
              <ArrowRight
                size={30}
                onClick={() => goNext(params.id)}
              ></ArrowRight>
              <LogoVeeam
                href={b.LINK}
                target="_blank"
                rel="noopener noreferrer"
              ></LogoVeeam>
              <LogoVeeam2
                href={b.LINK}
                target="_blank"
                rel="noopener noreferrer"
              ></LogoVeeam2>
              <VideoVeeam onClick={() => setPopUp("video")}></VideoVeeam>
              <AttachmentVeeam
                onClick={() => {
                  setPopUp("attachment");
                  setChatOpen(false);
                }}
              ></AttachmentVeeam>
              <ChatVeeam
                onClick={() => {
                  setChatOpen(true);
                  setIdBooth(b.BOOTH_ID);
                  setBoothName(b.NAME);
                }}
              ></ChatVeeam>
              <QuestionaireVeeam
                href={b.QUESTIONNAIRE}
                target="_blank"
                rel="noopener noreferrer"
              ></QuestionaireVeeam>
              <BoothChat
                data={b}
                token={token}
                chatOpen={chatOpen}
                idUserChat={idBooth}
                nameUserChat={boothName}
                handleUser={handleBooth}
                handleOpenCloseChat={handleOpenCloseChat}
              />
            </div>
          );
        } else {
          return (
            <div style={{ position: "relative" }} key={b.BOOTH_ID}>
              <Image src={RootAPI + "/files/booth/" + b.FOLDER + b.BANNER} />
              <ArrowBack
                size={30}
                onClick={() => history.push("/exhibition")}
              ></ArrowBack>
              <ArrowLeft
                size={30}
                onClick={() => goBack(params.id)}
              ></ArrowLeft>
              <ArrowRight
                size={30}
                onClick={() => goNext(params.id)}
              ></ArrowRight>
              <LogoGold
                href={b.LINK}
                target="_blank"
                rel="noopener noreferrer"
              ></LogoGold>
              <VideoGold onClick={() => setPopUp("video")}></VideoGold>
              <AttachmentGold
                onClick={() => {
                  setPopUp("attachment");
                  setChatOpen(false);
                }}
              ></AttachmentGold>
              <ChatGold
                onClick={() => {
                  setChatOpen(true);
                  setIdBooth(b.BOOTH_ID);
                  setBoothName(b.NAME);
                }}
              ></ChatGold>
              <QuestionaireGold
                href={b.QUESTIONNAIRE}
                target="_blank"
                rel="noopener noreferrer"
              ></QuestionaireGold>
              <BoothChat
                data={b}
                token={token}
                chatOpen={chatOpen}
                idUserChat={idBooth}
                nameUserChat={boothName}
                handleUser={handleBooth}
                handleOpenCloseChat={handleOpenCloseChat}
              />
            </div>
          );
        }
      })}

      {popUp && (
        <PopupContent>
          <RootPopUp popUp={popUp}>
            <AiFillCloseCircle
              size={30}
              style={{
                position: "absolute",
                right: -10,
                top: -15,
                cursor: "pointer",
              }}
              onClick={() => setPopUp(false)}
            ></AiFillCloseCircle>
            {popUp === "video" ? (
              <div style={{ width: "100%", height: "100%" }}>
                {boothById.map((booth) => {
                  return (
                    <iframe
                      src={booth.VIDEO[0].LINK + "?rel=0&autoplay=1"}
                      height="100%"
                      width="100%"
                      key={booth.BOOTH_ID}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="video"
                    ></iframe>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                {boothById.map((b) => {
                  return (
                    <RootAttachment key={b.BOOTH_ID}>
                      {b.ATTACHMENT.map((a) => {
                        return (
                          <AttachmentContent
                            key={a.ID}
                            href={
                              RootAPI +
                              "/files/boothattach/" +
                              a.FOLDER +
                              a.LINK
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            onClick={() => {
                              const dataLogDownload = {
                                EVENT_ID: token.eventid,
                                USER_ID: token.id,
                                ATTACHMENT_ID: a.ID,
                                ACTIVITY: "DOWNLOAD",
                                BOOTH_ID: b.BOOTH_ID,
                              };
                              API.post(
                                "/activitylog/boothlog",
                                dataLogDownload,
                                {
                                  headers: getAuthHeader(),
                                }
                              );
                            }}
                          >
                            <TextAttachment>{a.NAME}</TextAttachment>

                            <div style={{ marginLeft: 20 }}>
                              <AiOutlineDownload size={20}></AiOutlineDownload>
                            </div>
                          </AttachmentContent>
                        );
                      })}
                    </RootAttachment>
                  );
                })}
              </div>
            )}
          </RootPopUp>
        </PopupContent>
      )}
      {boothById.map((b) => {
        return (
          <div key={b.BOOTH_ID}>
            <a
              href={b.LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              <MobileMenu>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ backgroundColor: "white" }}>
                    <AiOutlineGlobal color="black" size={30} />
                  </Avatar>
                  <TextMobile>Visit Website</TextMobile>
                </div>
                <AiOutlineRight />
              </MobileMenu>
            </a>

            <MobileMenu
              onClick={() => {
                setPopUp("attachment");
                setChatOpen(false);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "white" }}>
                  <AiOutlineCloudDownload color="black" size={30} />
                </Avatar>{" "}
                <TextMobile>Download</TextMobile>
              </div>
              <AiOutlineRight />
            </MobileMenu>
            <MobileMenu
              onClick={() => {
                setPopUp("video");
                setChatOpen(false);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "white" }}>
                  <AiOutlineVideoCamera color="black" size={30} />
                </Avatar>{" "}
                <TextMobile>Video</TextMobile>
              </div>
              <AiOutlineRight />
            </MobileMenu>
            <MobileMenu
              onClick={() => {
                setChatOpen(true);
                setIdBooth(b.BOOTH_ID);
                setBoothName(b.NAME);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar style={{ backgroundColor: "white" }}>
                  <AiOutlineWechat color="black" size={30} />
                </Avatar>{" "}
                <TextMobile>Live Q&A Chat</TextMobile>
              </div>
              <AiOutlineRight />
            </MobileMenu>
            <a
              href={b.QUESTIONNAIRE}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              <MobileMenu>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ backgroundColor: "white" }}>
                    <AiOutlineQuestionCircle color="black" size={30} />
                  </Avatar>{" "}
                  <TextMobile>Questionnaire</TextMobile>
                </div>
                <AiOutlineRight />
              </MobileMenu>
            </a>
          </div>
        );
      })}
    </Root>
  );
};

export default Booth;
const Root = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${Color.background};
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: calc(100vh - 50px);
  margin-top: 50px;
`;
const AttachmentGold = styled.div`
  position: absolute;
  width: 120px;
  height: 100px;
  bottom: 22%;
  right: 26%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 100px;
  }
  @media (max-width: 1200px) {
    width: 85px;
    height: 90px;
  }
  @media (max-width: 1100px) {
    width: 75px;
    height: 80px;
  }
  @media (max-width: 1000px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 900px) {
    width: 65px;
    height: 60px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const AttachmentCloudFlare = styled.div`
  position: absolute;
  width: 75px;
  height: 60px;
  bottom: 18%;
  left: 41%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 70px;
    height: 65px;
  }
  @media (max-width: 1200px) {
    width: 65px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 60px;
    height: 55px;
  }
  @media (max-width: 1000px) {
    width: 55px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 50px;
    height: 45px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const AttachmentVeeam = styled.div`
  position: absolute;
  width: 65px;
  height: 65px;
  bottom: 16%;
  right: 31%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1200px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1100px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 1000px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const ChatCloudFlare = styled.div`
  position: absolute;
  width: 70px;
  height: 70px;
  bottom: 19%;
  left: 34.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 65px;
    height: 65px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1000px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const ChatVeeam = styled.div`
  position: absolute;
  width: 70px;
  height: 70px;
  bottom: 15%;
  right: 39%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 65px;
    height: 65px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1000px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const ChatGold = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  bottom: 22%;
  right: 39%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 100px;
  }
  @media (max-width: 1200px) {
    width: 85px;
    height: 110px;
  }
  @media (max-width: 1100px) {
    width: 75px;
    height: 100px;
  }
  @media (max-width: 1000px) {
    width: 70px;
    height: 90px;
  }
  @media (max-width: 900px) {
    width: 65px;
    height: 80px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const QuestionaireCLoudFlare = styled.a`
  position: absolute;
  width: 70px;
  height: 70px;
  bottom: 19%;
  left: 28%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 65px;
    height: 65px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1000px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const QuestionaireVeeam = styled.a`
  position: absolute;
  width: 70px;
  height: 70px;
  bottom: 14%;
  left: 47.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 65px;
    height: 65px;
  }
  @media (max-width: 1200px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 1100px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 1000px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 45px;
    height: 45px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const QuestionaireGold = styled.a`
  position: absolute;
  width: 100px;
  height: 120px;
  bottom: 22%;
  left: 41%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 90px;
    height: 110px;
  }
  @media (max-width: 1200px) {
    width: 80px;
    height: 100px;
  }
  @media (max-width: 1100px) {
    width: 70px;
    height: 90px;
  }
  @media (max-width: 1000px) {
    width: 60px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 50px;
    height: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoCloudFlare = styled.a`
  position: absolute;
  width: 120px;
  height: 80px;
  top: 24%;
  right: 18%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(10deg);
  @media (max-width: 1300px) {
    width: 110px;
  }
  @media (max-width: 1200px) {
    width: 100px;
    height: 75px;
  }
  @media (max-width: 1100px) {
    width: 90px;
    height: 70px;
  }
  @media (max-width: 1000px) {
    width: 80px;
    height: 65px;
  }
  @media (max-width: 900px) {
    width: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoCloudFlare2 = styled.a`
  position: absolute;
  width: 170px;
  height: 90px;
  top: 24%;
  left: 11%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-8deg);
  @media (max-width: 1300px) {
    width: 160px;
  }
  @media (max-width: 1200px) {
    width: 150px;
    height: 85px;
  }
  @media (max-width: 1100px) {
    width: 140px;
    height: 80px;
  }
  @media (max-width: 1000px) {
    width: 130px;
    height: 75px;
  }
  @media (max-width: 900px) {
    width: 120px;
    height: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoVeeam = styled.a`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 27%;
  left: 13.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(-10deg);
  @media (max-width: 1300px) {
    width: 75px;
    height: 75px;
  }
  @media (max-width: 1200px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 1100px) {
    width: 65px;
    height: 65px;
  }
  @media (max-width: 1000px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 900px) {
    width: 55px;
    height: 55px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoVeeam2 = styled.a`
  position: absolute;
  width: 150px;
  height: 80px;
  top: 27.5%;
  right: 12%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewY(5deg);
  @media (max-width: 1300px) {
    width: 140px;
    height: 75px;
  }
  @media (max-width: 1200px) {
    width: 130px;
    height: 70px;
  }
  @media (max-width: 1100px) {
    width: 120px;
    height: 65px;
  }
  @media (max-width: 1000px) {
    width: 110px;
    height: 60px;
  }
  @media (max-width: 900px) {
    width: 100px;
    height: 65px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const LogoGold = styled.a`
  position: absolute;
  width: 300px;
  height: 80px;
  top: 28%;
  right: 27%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 230px;
    height: 75px;
  }
  @media (max-width: 1200px) {
    width: 280px;
    height: 70px;
  }
  @media (max-width: 1100px) {
    width: 260px;
    height: 65px;
  }
  @media (max-width: 1000px) {
    width: 240px;
    height: 60px;
  }
  @media (max-width: 900px) {
    width: 220px;
    height: 55px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const VideoCLoudFlare = styled.div`
  position: absolute;
  width: 200px;
  height: 100px;
  bottom: 29%;
  left: 32.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 5px;
  border-radius: 5px;
  @media (max-width: 1300px) {
    width: 190px;
    height: 95px;
  }
  @media (max-width: 1200px) {
    width: 180px;
    height: 90px;
  }
  @media (max-width: 1100px) {
    width: 170px;
    height: 85px;
  }
  @media (max-width: 1000px) {
    width: 150px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 130px;
    height: 75px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const VideoVeeam = styled.div`
  position: absolute;
  width: 200px;
  height: 100px;
  bottom: 27%;
  right: 34.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 5px;
  @media (max-width: 1300px) {
    width: 190px;
    height: 90px;
  }
  @media (max-width: 1200px) {
    width: 180px;
    height: 80px;
  }
  @media (max-width: 1100px) {
    width: 170px;
    height: 70px;
  }
  @media (max-width: 1000px) {
    width: 150px;
    height: 75px;
  }
  @media (max-width: 900px) {
    width: 130px;
    height: 65px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const VideoGold = styled.div`
  position: absolute;
  width: 280px;
  height: 130px;
  bottom: 39.5%;
  left: 19.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  border-radius: 5px;
  @media (max-width: 1300px) {
    width: 260px;
    height: 120px;
  }
  @media (max-width: 1200px) {
    width: 240px;
    height: 110px;
  }
  @media (max-width: 1100px) {
    width: 220px;
    height: 100px;
  }
  @media (max-width: 1000px) {
    width: 200px;
    height: 90px;
  }
  @media (max-width: 900px) {
    width: 180px;
    height: 800px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const RootAttachment = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  /* background-color: red; */
`;
const AttachmentContent = styled.a`
  width: 80%;
  height: 50px;
  background-color: #060e26;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px;
  color: white;
  text-decoration: none;
  &:hover,
  :focus {
    color: white;
  }
`;
const PopupContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 50px);
  top: 50px;
  background-color: rgba(217, 240, 255, 0.7);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
`;
const MobileMenu = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    background-color: #2f9fae;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid white;
  } ;
`;
const TextMobile = styled.p`
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
`;
const RootPopUp = styled.div`
  width: 50%;
  height: ${(props) => (props.popUp === "video" ? "50%" : "auto")};
  background-color: ${(props) =>
    props.popUp === "video" ? "transparent" : "white"};
  position: relative;
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 90%;
  }
`;
const ArrowBack = styled(AiOutlineArrowLeft)`
  position: absolute;
  top: 8%;
  left: 3%;
  color: white;
  cursor: pointer;
`;
const ArrowLeft = styled(AiOutlineLeft)`
  position: absolute;
  top: 8%;
  left: 10%;
  color: white;
  cursor: pointer;
`;
const ArrowRight = styled(AiOutlineRight)`
  position: absolute;
  top: 8%;
  left: 15%;
  color: white;
  cursor: pointer;
`;
const TextAttachment = styled.p`
  margin: 0;
  font-size: 12px;
  @media (max-width: 992px) {
    font-size: 10px;
  }
  @media (max-width: 576px) {
    font-size: 9px;
  }
`;
