import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { API } from "../../config/GlobalRoot";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom";
const FormLoginUser = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onFinish = (values) => {
    const data = { ...values, eventid: 50 };
    setLoading(true);
    API.post("/auth/loginuser", data)
      .then((res) => {
        setLoading(false);
        const ciphertext = CryptoJS.AES.encrypt(
          `${res.data.accessToken}`,
          "_k3p0y@?"
        ).toString();
        const tripleDes = CryptoJS.TripleDES.encrypt(
          `${ciphertext}`,
          "_k3p0y@?"
        ).toString();

        const item = {
          accessToken: tripleDes,
          expiresIn: res.data.expiresIn,
        };
        localStorage.setItem("user", JSON.stringify(item));
        history.push("/");
      })
      .catch((err) => {
        if (err.response.status === 422) {
          Swal.fire({
            title: "Login Failed",
            text: `${err.response.data.message}`,
            icon: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        size="large"
        requiredMark="optional"
        style={{ width: "100%" }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <div style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </div>
      </Form>
    </>
  );
};

export default FormLoginUser;
