import React from "react";
import IsMe from "./IsMe";
import Other from "./Other";

const ChatItem = ({ isMe, text, time }) => {
  if (isMe) {
    return <IsMe text={text} time={time} />;
  }
  return <Other text={text} time={time} />;
};

export default ChatItem;
