import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Avatar } from "antd";
import {
  AiOutlineRight,
  AiFillCalendar,
  AiFillCloseCircle,
  AiFillInteraction,
  AiFillGold,
  AiFillSliders,
} from "react-icons/ai";
import getUserToken from "../../services/UserToken";
import getAuthHeader from "../../services/AuthHeader";
import { API, Color, RootAPI } from "../../config/GlobalRoot";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";

const GateTrack = () => {
  const [popUp, setPopUp] = useState("");
  const [dataSchedule, setDataSchedule] = useState([]);
  const [videoTransisi, setVideoTransisi] = useState("");
  const [mute, setMute] = useState(false);
  const history = useHistory();
  const token = getUserToken();

  useEffect(() => {
    API.get(`/lineup/listschedule/${token.eventid}`, {
      headers: getAuthHeader(),
    })
      .then((res) => {
        setDataSchedule(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const popUpTrack = () => {
    if (popUp) {
      if (popUp === "agenda") {
        return (
          <PopupAgenda>
            <RootPopUp>
              <AiFillCloseCircle
                size={30}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: -10,
                  right: -10,
                }}
                onClick={() => setPopUp(false)}
              ></AiFillCloseCircle>
              <div style={{ height: "100%", overflow: "auto", padding: 20 }}>
                {dataSchedule.map((sch) => {
                  return (
                    <WrapperContent key={sch.SCHEDULE_ID}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <WrapTime>
                          <Time>
                            {sch.START_TIME} - {sch.END_TIME}
                          </Time>
                        </WrapTime>
                        <WrapBadge>
                          <Badge>{sch.STAGE}</Badge>
                        </WrapBadge>
                      </div>

                      <Title>{sch.TITLE}</Title>
                      <WrapperSpeaker>
                        {sch.SPEAKER.map((spk) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                margin: "0 20px 10px 0",
                                width: 300,
                              }}
                              key={spk.SPEAKER_ID}
                            >
                              <div style={{ marginRight: 10 }}>
                                {" "}
                                <Avatar
                                  src={
                                    RootAPI +
                                    "/files/speaker/" +
                                    spk.FOLDER +
                                    spk.IMG
                                  }
                                />
                              </div>

                              <div>
                                <SpeakerName>{spk.NAME}</SpeakerName>
                                <SpeakeTitle>
                                  {spk.TITLE} at {spk.COMPANY}
                                </SpeakeTitle>
                              </div>
                            </div>
                          );
                        })}
                      </WrapperSpeaker>
                    </WrapperContent>
                  );
                })}{" "}
              </div>
            </RootPopUp>
          </PopupAgenda>
        );
      } else {
        return (
          <RootContentPopup>
            <RootVideo>
              <video
                autoPlay
                width="100%"
                height="auto"
                onEnded={() => history.push(`/${popUp}`)}
              >
                <source src={videoTransisi} type="video/mp4" />
              </video>
              <div style={{ bottom: 30, right: 40, position: "absolute" }}>
                <button
                  onClick={() => history.push(`/${popUp}`)}
                  style={{
                    backgroundColor: "#060e26",
                    padding: 10,
                    color: "white",
                    borderRadius: 5,
                    border: "1px solid #5af2f9",
                  }}
                >
                  SKIP VIDEO
                </button>
              </div>
            </RootVideo>
          </RootContentPopup>
        );
      }
    }
  };
  return (
    <Root>
      <div style={{ position: "relative" }}>
        <Image
          src="https://event.computradetech.com/images/ITSummit2022/VIDEO/LOOPING_GRADASI/track_SESSION%20hall_GRADASI.mp4"
          autoPlay
          loop
          muted
        />
        <Volume>
          {mute ? (
            <BsFillVolumeMuteFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          ) : (
            <BsFillVolumeUpFill
              size={30}
              color="#53f8fe"
              onClick={() => setMute(!mute)}
            />
          )}
        </Volume>
        <Track1
          onClick={() => {
            setPopUp("track/Connected Customer");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A1_Track1.mp4"
            );
          }}
        ></Track1>
        <TextTrack1
          onClick={() => {
            setPopUp("track/Connected Customer");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A1_Track1.mp4"
            );
          }}
        ></TextTrack1>
        <Track2
          onClick={() => {
            setPopUp("track/Connected System");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A2_Track2_rev.mp4"
            );
          }}
        ></Track2>
        <TextTrack2
          onClick={() => {
            setPopUp("track/Connected System");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A2_Track2_rev.mp4"
            );
          }}
        ></TextTrack2>
        <Track3
          onClick={() => {
            setPopUp("track/Connected Workforce");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A3_Track3.mp4"
            );
          }}
        ></Track3>
        <TextTrack3
          onClick={() => {
            setPopUp("track/Connected Workforce");
            setVideoTransisi(
              "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A3_Track3.mp4"
            );
          }}
        ></TextTrack3>
        <Agenda onClick={() => setPopUp("agenda")}></Agenda>
      </div>

      <MobileMenu
        onClick={() => {
          setPopUp("track/Connected Customer");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A1_Track1.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillSliders color="black" size={30} />
          </Avatar>{" "}
          <TextMobile> Connected Customer</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopUp("track/Connected System");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A2_Track2_rev.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillInteraction color="black" size={30} />
          </Avatar>{" "}
          <TextMobile> Connected System</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu
        onClick={() => {
          setPopUp("track/Connected Workforce");
          setVideoTransisi(
            "https://event.computradetech.com/images/ITSummit2022/VIDEO/TRANSISI/05A3_Track3.mp4"
          );
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillGold color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>Connected Workforce</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      <MobileMenu onClick={() => setPopUp("agenda")}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "white" }}>
            <AiFillCalendar color="black" size={30} />
          </Avatar>{" "}
          <TextMobile>Agenda</TextMobile>
        </div>
        <AiOutlineRight />
      </MobileMenu>
      {popUpTrack()}
      <audio autoPlay loop muted={mute}>
        <source
          src="https://event.computradetech.com/images/ITSummit2022/Music/music-lobby-track.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </Root>
  );
};

export default GateTrack;
const Root = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${Color.background};
  position: relative;
  overflow: hidden;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;
const MobileMenu = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    background-color: #2f9fae;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid white;
  } ;
`;
const TextMobile = styled.p`
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: 600;
`;
const Track1 = styled.div`
  position: absolute;
  width: 210px;
  height: 130px;
  top: 53%;
  left: 17%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 200px;
  }
  @media (max-width: 1200px) {
    width: 190px;
    height: 120px;
  }
  @media (max-width: 1100px) {
    width: 180px;
    height: 110px;
  }
  @media (max-width: 1000px) {
    width: 170px;
    height: 100px;
  }
  @media (max-width: 900px) {
    width: 140px;
    height: 90px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TextTrack1 = styled.div`
  position: absolute;
  width: 210px;
  height: 95px;
  top: 38%;
  left: 18%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 190px;
  }
  @media (max-width: 1200px) {
    width: 170px;
    height: 90px;
  }
  @media (max-width: 1100px) {
    width: 150px;
    height: 85px;
  }
  @media (max-width: 1000px) {
    width: 140px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 130px;
    height: 75px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Track2 = styled.div`
  position: absolute;
  width: 210px;
  height: 120px;
  top: 53%;
  left: 41.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 190px;
  }
  @media (max-width: 1200px) {
    width: 180px;
  }
  @media (max-width: 1100px) {
    width: 170px;
    height: 110px;
  }
  @media (max-width: 1000px) {
    width: 150px;
    height: 100px;
  }
  @media (max-width: 900px) {
    width: 140px;
    height: 90px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TextTrack2 = styled.div`
  position: absolute;
  width: 210px;
  height: 95px;
  top: 38%;
  left: 42%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 190px;
  }
  @media (max-width: 1200px) {
    width: 170px;
    height: 90px;
  }
  @media (max-width: 1100px) {
    width: 150px;
    height: 85px;
  }
  @media (max-width: 1000px) {
    width: 140px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 130px;
    height: 75px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Track3 = styled.div`
  position: absolute;
  width: 210px;
  height: 120px;
  top: 53%;
  right: 19.5%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 200px;
  }
  @media (max-width: 1200px) {
    width: 190px;
  }
  @media (max-width: 1100px) {
    width: 180px;
    height: 110px;
  }
  @media (max-width: 1000px) {
    width: 170px;
    height: 100px;
  }
  @media (max-width: 900px) {
    width: 150px;
    height: 90px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const TextTrack3 = styled.div`
  position: absolute;
  width: 210px;
  height: 95px;
  top: 38%;
  right: 20%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 190px;
  }
  @media (max-width: 1200px) {
    width: 170px;
    height: 90px;
  }
  @media (max-width: 1100px) {
    width: 150px;
    height: 85px;
  }
  @media (max-width: 1000px) {
    width: 140px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 130px;
    height: 75px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const Agenda = styled.div`
  position: absolute;
  width: 110px;
  height: 140px;
  bottom: 23%;
  right: 11%;
  /* background-color: rgba(217, 240, 255, 0.7); */
  cursor: pointer;
  transform: skewX(-5deg);
  border-radius: 10px;
  @media (max-width: 1300px) {
    height: 130px;
    width: 100px;
  }
  @media (max-width: 1200px) {
    height: 120px;
    width: 90px;
  }
  @media (max-width: 1100px) {
    height: 100px;
    width: 80px;
  }
  @media (max-width: 1000px) {
    height: 90px;
  }
  @media (max-width: 900px) {
    height: 80px;
    width: 70px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const PopupAgenda = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 50px);
  top: 50px;
  background-color: rgba(217, 240, 255, 0.7);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
`;
const Image = styled.video`
  width: 100%;
  height: auto;
  max-height: calc(100vh - 50px);
  margin-top: 50px;
  object-fit: cover;
`;
const Time = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;
const WrapTime = styled.div`
  border-radius: 5px;
  padding: 5px;
  background-color: grey;
  color: white;
  display: flex;
  justify-content: center;
`;
const Badge = styled(Time)`
  font-size: 10px;
`;
const WrapBadge = styled(WrapTime)`
  background-color: #060e26;
`;
const Title = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
`;
const WrapperContent = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid grey;
  @media (max-width: 576px) {
    margin-bottom: 15px;
  }
`;
const WrapperSpeaker = styled.div`
  display: flex;
  margin: 10px 0;
  flex-flow: wrap;
`;
const SpeakerName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
`;
const SpeakeTitle = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
`;
const RootPopUp = styled.div`
  width: 50%;
  height: 50%;
  background-color: white;
  position: relative;
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 80%;
    height: 80%;
  }
`;
const RootContentPopup = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100% - 50px);
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 3;
  background-color: rgba(45, 111, 155, 0.8);
`;
const RootVideo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;
const Volume = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 15%;
  }
  @media (max-width: 576px) {
    top: 20%;
  }
`;
