import React from "react";
import { Switch, useLocation, Route } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Notification from "../../components/Notification/Notification";
import Booth from "./Booth";
import Entrace from "./Entrace";
import Exhibition from "./Exhibition";
import FAQ from "./FAQ";
import Games from "./Games";
import GateTrack from "./GateTrack";
import Lobby from "./Lobby";
import MainHall from "./MainHall";
import NotFound from "./NotFound";
import PlayGame from "./PlayGame";
import Schedule from "./Schedule";
import TrackHall from "./TrackHall";

const RootUser = () => {
  const location = useLocation();
  return (
    <div>
      <Navbar location={location.pathname} />
      <Switch>
        <Route exact path="/">
          <Entrace />
        </Route>
        <Route path="/lobby">
          <Lobby />
        </Route>
        <Route path="/agenda">
          <Schedule />
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/Main Hall">
          <MainHall />
        </Route>
        <Route path="/track/:name">
          <TrackHall />
        </Route>
        <Route path="/track">
          <GateTrack />
        </Route>
        <Route path="/exhibition/:id">
          <Booth />
        </Route>
        <Route path="/exhibition">
          <Exhibition />
        </Route>
        <Route path="/games/:id">
          <PlayGame />
        </Route>
        <Route path="/games">
          <Games />
        </Route>
        <Route component={NotFound} />
      </Switch>
      <Notification />
    </div>
  );
};

export default RootUser;
