import React, { useEffect, useState } from "react";
import Fire from "../../config/Firebase";
import moment from "moment";
import InputEmoji from "react-input-emoji";
import ScrollToBottom from "react-scroll-to-bottom";
import "./chat.css";
import ChatItem from "./ChatItem";
import styled from "styled-components";
const Chat = ({ idReceiver, nameUserChat, token }) => {
  const [chatUser, setChatUser] = useState([]);
  const time = moment().format("YYYY-MM-DD HH:mm:ss:SS");
  const name = `${token.loginName}`;
  useEffect(() => {
    let update = 1;
    Fire.database()
      .ref(`/chat/${token.urlname}/BoothChat/${token.id}_${idReceiver}`)
      .limitToLast(200)
      .on("value", (snapshot) => {
        if (snapshot.val() && update === 1) {
          const dataSnapshot = snapshot.val();
          const allDataChat = [];
          Object.keys(dataSnapshot).map((key) => {
            allDataChat.push({
              id: key,
              data: dataSnapshot[key],
            });
            return dataSnapshot;
          });
          const updateDataIsRead = {
            idReceiver: token.id,
            id: idReceiver,
            name: nameUserChat,
            lastChat: allDataChat[allDataChat.length - 1].data.chat,
            timeChat: allDataChat[allDataChat.length - 1].data.time,
            isRead: 1,
          };
          {
            update === 1 &&
              Fire.database()
                .ref(
                  `chat/${token.urlname}/HistoryChatBoothUser/${token.id}/${token.id}_${idReceiver}`
                )
                .update(updateDataIsRead);
          }
          setChatUser(allDataChat);
        }
      });

    return () => {
      setChatUser([]);
      update = 0;
    };
  }, [idReceiver]);

  function handleOnEnter(text) {
    const dataToFire = {
      chat: text,
      sender: name,
      time: time,
      idSender: token.id,
      idReceiver: idReceiver,
      user: token.loginName,
      user_id: token.id,
      booth: nameUserChat,
    };
    const updateData = {
      lastChat: text,
      timeChat: time,
      idReceiver: idReceiver,
      id: token.id,
      name: token.loginName,
      isRead: 0,
    };
    const updateDataIsMe = {
      lastChat: text,
      timeChat: time,
      name: nameUserChat,
      id: idReceiver,
      isRead: 1,
    };
    text.trim().length > 0 &&
      Fire.database()
        .ref(`/chat/${token.urlname}/BoothChat/${token.id}_${idReceiver}`)
        .push(dataToFire);
    text.trim().length > 0 &&
      Fire.database()
        .ref(
          `chat/${token.urlname}/HistoryChatBoothUser/${token.id}/${token.id}_${idReceiver}`
        )
        .update(updateDataIsMe);
    text.trim().length > 0 &&
      Fire.database()
        .ref(
          `chat/${token.urlname}/HistoryChatBoothAdmin/${idReceiver}/${token.id}_${idReceiver}`
        )
        .update(updateData);
  }

  return (
    <Root>
      <ChatComponent>
        <Scroll>
          {chatUser.map((chat) => {
            return (
              <ChatItem
                isMe={chat.data.sender === token.loginName}
                key={chat.id}
                text={chat.data.chat}
                name={chat.data.sender}
                time={chat.data.time}
              />
            );
          })}
        </Scroll>
      </ChatComponent>
      <InputChat>
        <InputEmoji
          cleanOnEnter
          onEnter={handleOnEnter}
          placeholder="Type a message & enter"
          borderRadius={0}
        />
      </InputChat>
    </Root>
  );
};

export default Chat;
const Root = styled.div`
  height: 100%;
  width: 100%;
`;
const ChatComponent = styled.div`
  height: 85%;
  background-image: url("https://event.computradetech.com/images/iko21/chatbg.png");
  padding: 10px;
  /* padding-top: 20px; */
  overflow: auto;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 576px) {
    padding: 5px;
    padding-top: 10px;
  } ;
`;
const Scroll = styled(ScrollToBottom)`
  height: 100%;
  width: 100%;
`;
const InputChat = styled.div`
  background-color: #060e26;
  height: 15%;
  display: flex;
  align-items: center;
  /* padding: 5px; */
  border-bottom-right-radius: 6px;
`;
