import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import styled from "styled-components";
import getUserToken from "../../services/UserToken";
import getAuthHeader from "../../services/AuthHeader";
import { API, Color } from "../../config/GlobalRoot";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";

const FAQ = () => {
  const { Panel } = Collapse;
  const token = getUserToken();
  const [dataFaq, setDataFaq] = useState([]);
  const [mute, setMute] = useState(false);

  useEffect(() => {
    API.get(`/faq/listbyeventid/${token.eventid}`, {
      headers: getAuthHeader(),
    })
      .then((res) => {
        setDataFaq(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Root>
      <div style={{ position: "relative" }}>
        <Image
          src="https://event.computradetech.com/images/ITSummit2022/VIDEO/LOOPING_GRADASI/09A%20FAQ%20GRADASI.mp4"
          autoPlay
          loop
          muted
        />

        <WrapRoot>
          <WrapperFaq>
            <Collapse accordion>
              {dataFaq.map((faq) => {
                return (
                  <Panel header={faq.QUESTION} key={faq.ID}>
                    <p>{faq.ANSWER}</p>
                  </Panel>
                );
              })}
            </Collapse>
          </WrapperFaq>
        </WrapRoot>
        <Volume>
          {mute ? (
            <BsFillVolumeMuteFill size={30} onClick={() => setMute(!mute)} />
          ) : (
            <BsFillVolumeUpFill size={30} onClick={() => setMute(!mute)} />
          )}
        </Volume>
      </div>
      <audio autoPlay loop muted={mute}>
        <source
          src="https://event.computradetech.com/images/ITSummit2022/Music/music-lobby-track.mp3"
          type="audio/mpeg"
        ></source>
      </audio>
    </Root>
  );
};

export default FAQ;
const Root = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${Color.background};
  overflow: hidden;
  @media (max-width: 800px) {
    overflow: auto;
  }
`;
const WrapperFaq = styled.div`
  background-color: rgba(236, 236, 236, 0.8);
  width: 50%;
  height: 80%;
  overflow: auto;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 576px) {
    width: 80%;
  }
`;
const WrapRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  position: absolute;
  width: 100%;
  top: 0;
  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;
const Image = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
const Volume = styled.div`
  position: absolute;
  top: 10%;
  right: 5%;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 15%;
  }
  @media (max-width: 576px) {
    top: 20%;
  }
`;
